import PropTypes from "prop-types";
import { t } from "i18n-js";
import { usePunditUserContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { ProfileFieldWithIcon } from "../../ProfileFieldWithIcon";
import { FieldTypeDisplayInformation } from "./FieldTypeDisplayInformation";
import { FieldTypePreviewInformation } from "./FieldTypePreviewInformation";

export const UserInformation = ({ fields, isPreview, isHidden }) => {
  const { currentCommunityMember } = usePunditUserContext();
  const isAdmin = isCommunityAdmin(currentCommunityMember);

  return (
    <div className="grid gap-6">
      {fields.map(field => {
        const { id, label, field_type: fieldType } = field;

        let text = FieldTypePreviewInformation[fieldType](field);
        if (!isPreview) {
          text = FieldTypeDisplayInformation[fieldType](field);
          if (isAdmin && text == null) {
            text = (
              <span className="opacity-60">{t("profile_fields.empty")}</span>
            );
          }
        }

        if (!isAdmin && text == null) {
          return null;
        }

        return (
          <ProfileFieldWithIcon
            key={id}
            title={label}
            text={text}
            isHidden={isHidden}
            dataTestId="user-profile-field"
          />
        );
      })}
    </div>
  );
};

UserInformation.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  isPreview: PropTypes.bool,
  isHidden: PropTypes.bool,
};
