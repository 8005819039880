import { useQuery } from "react-query";
import { workflowsApi } from "@/react/api";

export const useCommunityCurrentUsageStats = (queryOptions = {}) =>
  useQuery(
    ["workflows-community-current-usage-stats"],
    () => workflowsApi.fetchCommunityCurrentUsageStats(),
    {
      ...queryOptions,
    },
  );
