import type { ItemProps } from "@circle-react-shared/uikit/Form/Item";
import { Form } from "@circle-react-uikit/Form";

interface ToggleSwitchFormItemProps extends ItemProps {
  name: string;
}

export const ToggleSwitchFormItem = ({
  name,
  isDisabled,
  ...rest
}: ToggleSwitchFormItemProps) => (
  <Form.Item
    hiddenField
    hideDescription
    hideBorder
    className="flex flex-row-reverse justify-end py-0"
    labelClassName="ml-4 mb-0 !font-normal"
    labelWrapperClassName="flex items-center"
    shouldToggleValueOnLabelClick
    translationRoot="profile_fields.form"
    name={name}
    isDisabled={isDisabled}
    {...rest}
  >
    <Form.ToggleSwitch variant="small" name={name} disabled={isDisabled} />
  </Form.Item>
);
