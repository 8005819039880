import { Icon } from "@circle-react/components/shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";

interface RestrictionWrapperProps {
  features: string[];
}

export const RestrictionWrapper = ({ features }: RestrictionWrapperProps) => (
  <ul className="list-none">
    {features?.map(feature => (
      <li key={feature}>
        <Typography.BodyMd>
          <Icon size={6} type="middot" /> {feature}
        </Typography.BodyMd>
      </li>
    ))}
  </ul>
);
