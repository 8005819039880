import Slider from "@material-ui/core/Slider";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {},
  margin: {
    height: theme.spacing(1),
  },
}));

const StyledSlider = withStyles({
  root: {
    height: 8,
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: "#fff",
    border: "2px solid rgba(138, 148, 166, 0.3)",
    marginTop: -8,
    marginLeft: -10,
    "&:focus,&:hover,&$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 4,
    borderRadius: 2,
    backgroundColor: "#506cf0",
  },
  rail: {
    height: 4,
    borderRadius: 2,
    backgroundColor: "#e5e7eb",
  },
})(Slider);

export interface CropSliderProps {
  value: number;
  onChange: (event: any, value: number | number[]) => void;
}

export const CropSlider = ({ value, onChange }: CropSliderProps) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.margin} />
      <StyledSlider
        value={value}
        min={1}
        max={3}
        step={0.1}
        aria-labelledby="Zoom"
        onChange={onChange}
      />
    </div>
  );
};
