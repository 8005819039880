import { useQuery } from "react-query";
import { planApi } from "@/react/api";

interface UseFetchProrationProps {
  new_plan_id: number;
  plan_coupon_id?: string;
}

interface UseQueryFetchProration {
  prorationData: any;
}
interface UseFetchProrationResult extends UseQueryFetchProration {
  isProrationLoading: boolean;
  errorFetchingProration: any; // [TODO][BillingReactification][AfterRelease] Create error type
}
export const useFetchProration = ({
  new_plan_id,
  plan_coupon_id = "",
}: UseFetchProrationProps): UseFetchProrationResult => {
  const queryKey = ["proration", new_plan_id, plan_coupon_id];

  const {
    data,
    isLoading: isProrationLoading,
    error: errorFetchingProration,
  } = useQuery<UseQueryFetchProration, Error>(queryKey, () =>
    planApi.proration({ new_plan_id, plan_coupon_id }),
  );

  const prorationData = data ?? {};

  return {
    prorationData,
    isProrationLoading,
    errorFetchingProration,
  };
};
