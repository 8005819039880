import { t } from "i18n-js";
import { LineItem } from "./LineItem";

export const TotalDue = ({ totalDue }: any) => (
  <LineItem
    title={t("pricing_plans.change_plan.total_due")}
    amount={totalDue}
    semibold
    amountWeight="semibold"
  />
);
