import { t } from "i18n-js";

export const UnsplashTab = () => (
  <span className="unsplash-label text-dark">
    <svg
      className="unsplash-label__icon"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 0H5V4.5H11V0ZM5.0145 7H0V16H16V7H11.0277V11.54H5.0145V7Z"
        fill="currentColor"
      />
    </svg>{" "}
    {t("trix_editor.toolbar.unsplash")}
  </span>
);
