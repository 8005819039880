import classnames from "classnames";
import { t } from "i18n-js";
import { Icon } from "@circle-react-shared/Icon";
import { BadgeV2 } from "@circle-react-shared/uikit/BadgeV2";
import { Typography } from "@circle-react-uikit/Typography";

export const LineItem = ({
  displayName,
  amountWithoutCurrency,
  displayIntervalText,
  description,
  isDoubleUpgrade,
  isFirstLineItem = false,
  featuresToDisplay = [],
  mhCurrentUsage,
  mhMaximumTierUsage,
  isFreeTier,
}) => (
  <div
    className={classnames({
      "border-primary border-b pb-4": isFirstLineItem,
      "pt-4": isDoubleUpgrade && !isFirstLineItem,
    })}
  >
    <div className="flex flex-row items-start justify-between">
      <div>
        <div>
          <div className="flex items-center gap-2">
            <Typography.LabelMd weight="semibold">
              {displayName}
            </Typography.LabelMd>
            {isFreeTier && (
              <BadgeV2
                label={t(
                  "pricing_plans.upgrade_modal.marketing_hub_line_item.free_tier",
                )}
              />
            )}
          </div>
        </div>

        <div className="mt-2 flex items-baseline gap-1">
          {mhMaximumTierUsage && (
            <Typography.LabelSm weight="bold">
              {t(
                "pricing_plans.upgrade_modal.marketing_hub_line_item.contacts",
                {
                  count: mhMaximumTierUsage,
                },
              )}
            </Typography.LabelSm>
          )}
          {mhCurrentUsage && (
            <Typography.LabelXs>
              {t(
                "pricing_plans.upgrade_modal.marketing_hub_line_item.currently_in_use",
                {
                  count: mhCurrentUsage,
                },
              )}
            </Typography.LabelXs>
          )}
        </div>

        <div className="max-w-xs leading-4">
          <Typography.LabelXs color="text-default" className="mt-1">
            {description}
          </Typography.LabelXs>
        </div>
        {isDoubleUpgrade && (
          <div className="mt-2 flex flex-col items-baseline gap-1">
            {featuresToDisplay.map(feature => (
              <li key={feature} className="mb-1 flex items-center gap-2">
                <Icon type="check" size={16} className="!h-4 !w-4" />
                <Typography.LabelXs
                  key={feature}
                  weight="semibold"
                  color="text-default"
                >
                  {feature}
                </Typography.LabelXs>
              </li>
            ))}
          </div>
        )}
      </div>
      <div className="">
        <div className="flex flex-col items-end gap-1">
          <Typography.TitleSm weight="bold">{`${amountWithoutCurrency}`}</Typography.TitleSm>
          <Typography.LabelXs weight="medium" color="text-default">
            {displayIntervalText}
          </Typography.LabelXs>
        </div>
      </div>
    </div>
  </div>
);
