import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { reactQueryPost } from "@/react/helpers/backendRequestHelpers";
import { accountPaymentProcessors } from "@circle-react/helpers/urlHelpers/internalApi/accountPaymentProcessors";
import { useToast } from "@circle-react-uikit/ToastV2";
import { UPGRADE_PATHS } from "../constants";

interface CheckoutSessionParams {
  plan_id: number;
  coupon_id: number;
  source: string;
  tax_id_data: string | null;
}

export const useAccountPaymentProcessorsApi = () => {
  const history = useHistory();
  const toast = useToast();

  const isInvalidCustomerDetailsError = (error: any): boolean =>
    error.errorDetails &&
    error.errorDetails.error_code === "INVALID_CUSTOMER_DETAILS";

  const { mutate: createCheckoutSession } = useMutation(
    ({ plan_id, coupon_id, source, tax_id_data }: CheckoutSessionParams) =>
      reactQueryPost(accountPaymentProcessors.checkoutSession.create(), {
        plan_id,
        coupon_id,
        source,
        tax_id_data,
      }),
    {
      onSuccess: (data: any) => {
        window.location.href = data.url;
      },
      onError: (error: any) => {
        if (isInvalidCustomerDetailsError(error)) {
          toast.error(error.message);
          history.push({
            pathname: UPGRADE_PATHS.TAX_ID_FORM,
          });
        } else {
          history.push(UPGRADE_PATHS.ERROR);
        }
      },
    },
  );

  return {
    createCheckoutSession,
  };
};
