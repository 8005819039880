import type { FieldTypes } from "../constants";
import { CustomField } from "./CustomField";
import type { CustomFieldProps } from "./CustomField";

type Props = Omit<CustomFieldProps, "fieldType" | "id" | "index">;

export interface Field extends Props {
  id: number;
  field_type: FieldTypes;
}

export interface CustomFieldsListProps {
  fields: Field[];
  indexUpdate?: number;
}

export const CustomFieldsList = ({
  fields,
  indexUpdate = 0,
}: CustomFieldsListProps) => (
  <>
    {fields.map(({ id, field_type, ...props }, index) => (
      <CustomField
        key={id}
        index={indexUpdate + index}
        fieldType={field_type}
        {...props}
        dataTestId="profile-custom-field"
      />
    ))}
  </>
);
