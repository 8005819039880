import { useMemo } from "react";
import { uniqueId } from "lodash";
import { Form } from "@circle-react-uikit/Form";

export const NameField = () => {
  const id = useMemo(() => uniqueId("full-name-"), []);

  return (
    <Form.Item
      id={id}
      name="name"
      hideDescription
      hideBorder
      translationRoot="profile_fields.form"
      fullWidth
      placeholder=" "
      required
    >
      <Form.Input />
    </Form.Item>
  );
};

NameField.propTypes = {};
