import type { ReactNode } from "react";
import classNames from "classnames";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { Typography } from "@circle-react-uikit/Typography";

export interface BodyProps {
  title?: string;
  children: ReactNode;
  className?: string;
}

export const Body = ({ title, children, className }: BodyProps) => {
  const isSmScreen = useSmScreenMediaQuery();
  const ResponsiveTypographyComponent = isSmScreen
    ? Typography.TitleSm
    : Typography.TitleMd;
  return (
    <div
      className={classNames(
        "flex flex-col gap-y-3 px-5 pb-10 pt-0 md:px-6 md:pt-2",
        className,
      )}
    >
      {title && (
        <ResponsiveTypographyComponent weight="font-bold">
          {title}
        </ResponsiveTypographyComponent>
      )}
      {children}
    </div>
  );
};
