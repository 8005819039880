import classNames from "classnames";
import { t } from "i18n-js";

interface DeletedMemberMessageProps {
  wrapperClassName?: string;
}

export const DeletedMemberMessage = ({
  wrapperClassName,
}: DeletedMemberMessageProps) => (
  <div
    className={classNames(
      "flex flex-1 flex-col items-center justify-center p-4 text-center",
      wrapperClassName,
    )}
  >
    <h2 className="text-xl font-bold">{t("profile_deleted_message")}</h2>
    <p className="text-sm">{t("profile_deleted_description")}</p>
  </div>
);
