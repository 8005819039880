import { useState } from "react";
import { t } from "i18n-js";
import { useFocusOnLoad } from "../../../../hooks/useFocusOnLoad";
import { useEditorApi } from "../TrixEditorContext";
import { embedContent } from "../helpers";

const defaultHelperText = t("trix_editor.modal_copy.embed_helper_text");

export const EmbedForm = ({
  onClose,
  embedPlaceholder: placeholder,
  embedDesc: description,
  helperText,
  embedBtn: button,
}) => {
  const { trixApiWrapper } = useEditorApi();
  const [value, setValue] = useState("");
  const inputRef = useFocusOnLoad();

  const embedUrlContent = () => {
    onClose && onClose();
    embedContent({ content: value, trixApiWrapper });
  };

  const onSubmit = async event => {
    event.preventDefault();
    await embedUrlContent();
  };

  const hasDescription = Boolean(description);
  const hasHelperText = helperText !== defaultHelperText;
  const shouldShowHelperText = !hasDescription && hasHelperText;

  return (
    <form onSubmit={onSubmit}>
      <input
        ref={inputRef}
        value={value}
        style={{ borderWidth: 1 }}
        className="upload-input placeholder:text-light placeholder:opacity-100"
        placeholder={placeholder}
        onChange={event => {
          setValue(event.target.value);
        }}
        type="text"
      />
      <button
        type="submit"
        className="btn btn-primary upload-btn"
        onClick={onSubmit}
      >
        {button}
      </button>
      <div className="upload-embed__description">
        {hasDescription && description}
        {shouldShowHelperText && helperText}
        {!hasHelperText && (
          <span>
            {t("trix_editor.embed_form.works_with")}{" "}
            <a
              href="https://embed.ly/providers#service-search"
              target="_blank"
              rel="noreferrer"
            >
              {t("trix_editor.embed_form.content_providers")}
            </a>
            {t("trix_editor.embed_form.including")}
          </span>
        )}
      </div>
    </form>
  );
};
