import type { ReactNode } from "react";
import type { Argument } from "classnames";
import classNames from "classnames";
import { Typography } from "@circle-react-uikit/Typography";

export interface RootProps {
  title: string;
  children: ReactNode;
  titleClassName?: Argument;
  rightSideContent?: ReactNode;
}

export const Root = ({
  title,
  children,
  rightSideContent,
  titleClassName = "",
}: RootProps) => (
  <>
    <div
      className={classNames("px-5 pt-6 md:p-6 lg:py-8", titleClassName, {
        "flex justify-between": rightSideContent,
      })}
    >
      <Typography.TitleMd weight="font-bold" color="text-very-dark">
        {title}
      </Typography.TitleMd>
      {rightSideContent && <div>{rightSideContent}</div>}
    </div>
    {children}
  </>
);
