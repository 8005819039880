import { t } from "i18n-js";
import { usePunditUserContext } from "@circle-react/contexts";
import {
  DISPLAY_OPTION_FIELD_INPUTS,
  FIELD_ICONS,
  FIELD_TYPES,
} from "../constants";
import { HeadlineField, LocaleField, NameField, TimezoneField } from "./Fields";

interface FormComponent {
  id: string;
  component: React.ElementType;
}

interface FieldConfig {
  icon: string;
  getLabel: () => string;
}

interface SidebarItem {
  id: string;
  show: (pageName?: string) => boolean;
  item: FieldConfig;
}

// Custom hook to generate form components and field lists
const useStaticFieldConfig = (): {
  STATIC_FORM_COMPONENTS: FormComponent[];
  STATIC_FIELD_LIST: { [key: string]: FieldConfig };
  STATIC_SIDEBAR_LIST_VISIBLE: SidebarItem[];
  STATIC_SIDEBAR_LIST_NOT_VISIBLE: SidebarItem[];
} => {
  const { currentCommunity = {} } = usePunditUserContext();
  const isLocaleSettingEnabled =
    currentCommunity.community_member_locale_setting_enabled;

  // Helper function to conditionally include items
  const includeIf = <T>(condition: boolean, items: T[]): T[] =>
    condition ? items : [];

  const STATIC_FORM_COMPONENTS: FormComponent[] = [
    { id: "name", component: NameField },
    { id: "headline", component: HeadlineField },
    { id: "time_zone", component: TimezoneField },
    ...includeIf(isLocaleSettingEnabled, [
      { id: "locale", component: LocaleField },
    ]),
  ];

  const STATIC_FIELD_LIST: { [key: string]: FieldConfig } = {
    name: {
      icon: FIELD_ICONS[FIELD_TYPES.TEXT],
      getLabel: () => t("profile_fields.form.name"),
    },
    headline: {
      icon: FIELD_ICONS[FIELD_TYPES.TEXT],
      getLabel: () => t("profile_fields.form.headline"),
    },
    timezone: {
      icon: FIELD_ICONS.timezone,
      getLabel: () => t("profile_fields.form.time_zone"),
    },
    memberSince: {
      icon: FIELD_ICONS.date,
      getLabel: () => t("profile_fields.form.member_join_date"),
    },
    ...(isLocaleSettingEnabled
      ? {
          locale: { icon: FIELD_ICONS.timezone, getLabel: () => t("locale") },
        }
      : {}),
  };

  const STATIC_SIDEBAR_LIST_VISIBLE: SidebarItem[] = [
    { id: "name", show: () => true, item: STATIC_FIELD_LIST["name"] },
    { id: "headline", show: () => true, item: STATIC_FIELD_LIST["headline"] },
    {
      id: "time_zone",
      show: pageName => pageName !== DISPLAY_OPTION_FIELD_INPUTS.PROFILE_VIEW,
      item: STATIC_FIELD_LIST["timezone"],
    },
    {
      id: "memberSince",
      show: pageName => pageName === DISPLAY_OPTION_FIELD_INPUTS.PROFILE_VIEW,
      item: STATIC_FIELD_LIST["memberSince"],
    },
    ...includeIf(isLocaleSettingEnabled, [
      { id: "locale", show: () => true, item: STATIC_FIELD_LIST["locale"] },
    ]),
  ];

  const STATIC_SIDEBAR_LIST_NOT_VISIBLE: SidebarItem[] = [
    {
      id: "memberSince",
      show: pageName => pageName !== DISPLAY_OPTION_FIELD_INPUTS.PROFILE_VIEW,
      item: STATIC_FIELD_LIST["memberSince"],
    },
    {
      id: "time_zone",
      show: pageName => pageName === DISPLAY_OPTION_FIELD_INPUTS.PROFILE_VIEW,
      item: STATIC_FIELD_LIST["timezone"],
    },
    ...includeIf(isLocaleSettingEnabled, [
      {
        id: "locale",
        show: (pageName: any) =>
          pageName !== DISPLAY_OPTION_FIELD_INPUTS.PROFILE_VIEW,
        item: STATIC_FIELD_LIST["locale"],
      },
    ]),
  ];

  return {
    STATIC_FORM_COMPONENTS,
    STATIC_FIELD_LIST,
    STATIC_SIDEBAR_LIST_VISIBLE,
    STATIC_SIDEBAR_LIST_NOT_VISIBLE,
  };
};

export default useStaticFieldConfig;
