import {
  Bold,
  Code,
  HThree,
  HTwo,
  Italic,
  Link,
  List,
  NumList,
  Quote,
  Strikethrough,
} from "./ToolbarIcons";

export const ACTIONS = [
  {
    label: <Bold />,
    showInInlineToolbar: true,
    attribute: "bold",
  },
  {
    label: <Italic />,
    showInInlineToolbar: true,
    attribute: "italic",
  },
  {
    label: <HTwo />,
    attribute: "heading2",
    showInInlineToolbar: true,
    shortcutPatten: "# ",
  },
  {
    label: <HThree />,
    attribute: "heading3",
    showInInlineToolbar: true,
    shortcutPatten: "## ",
  },
  {
    label: <Quote />,
    showInInlineToolbar: true,
    attribute: "quote",
  },
  {
    label: <Code />,
    attribute: "code",
  },
  {
    label: <Strikethrough />,
    showInInlineToolbar: true,
    attribute: "strike",
  },
  {
    label: <Link />,
    showInInlineToolbar: true,
    attribute: "href",
  },
  {
    label: <List />,
    attribute: "bullet",
    showInInlineToolbar: true,
    shortcutPatten: "- ",
  },
  {
    label: <NumList />,
    attribute: "number",
    showInInlineToolbar: true,
    shortcutPatten: "1.",
  },
  {
    attribute: "divider",
    showInInlineToolbar: false,
    shortcutPatten: "---",
  },
];

export const SHORTCUT_PATTERN_ACTIONS = ACTIONS.filter(
  action => action.shortcutPatten,
);
export const INLINE_TOOLBAR_ACTIONS = ACTIONS.filter(
  action => action.showInInlineToolbar,
);
