import { t } from "i18n-js";
import type { Basic } from "unsplash-js/src/methods/photos/types";

export interface UnsplashTabContentProps {
  unsplashResults: Basic[];
  searchUnsplash: (value: string) => void;
  addUnsplash: (item: Basic) => void;
}

export const UnsplashTabContent = ({
  unsplashResults,
  searchUnsplash,
  addUnsplash,
}: UnsplashTabContentProps) => (
  <>
    <input
      type="text"
      style={{ borderWidth: 1 }}
      className="upload-input placeholder:text-light placeholder:opacity-100"
      placeholder={t("image_upload.search_unsplash")}
      onChange={event => searchUnsplash(event.target.value)}
    />
    <div className="unsplash-tab__result-grid">
      {unsplashResults.length >= 1 &&
        unsplashResults.map(item => (
          <button
            type="button"
            className="unsplash-tab__result text-left"
            key={item.id}
            onClick={() => addUnsplash(item)}
          >
            <img
              loading="lazy"
              className="unsplash-tab__result--image"
              src={item.urls.regular}
              alt={item.alt_description || ""}
            />

            <div className="unsplash-tab__result--info">
              <div className="unsplash-tab__result--author">
                {t("image_upload.by")}{" "}
                <a href={`${item.links.html}`} target="_blank" rel="noreferrer">
                  {item.user.first_name} {item.user.last_name}
                </a>
              </div>
            </div>
          </button>
        ))}
    </div>
  </>
);
