import { useEditorApi } from "../TrixEditorContext";
import { useEditorMode } from "../useEditorMode";
import { Selector } from "./Selector";

export const InlineModalSelector = () => {
  const {
    subMode,
    subType,
    showInlineModalSelector,
    resetEditorMode,
    restoreToLastKnownPosition,
  } = useEditorMode();

  const { editorElement } = useEditorApi();
  const isVisible = showInlineModalSelector();

  if (!isVisible) {
    return null;
  }

  const onClose = async () => {
    await resetEditorMode();
    await restoreToLastKnownPosition();
    editorElement.focus();
  };

  return (
    <div className="inline-modal-selector" style={{ height: 0 }}>
      <Selector attribute={subMode} subtype={subType} onClose={onClose} />
    </div>
  );
};
