import PropTypes from "prop-types";
import classNames from "classnames";
import { Block } from "./Block";

export const SmallSquareWithBorder = ({ className, ...rest }) => (
  <Block
    className={classNames(
      "border-tertiary h-4 w-4 rounded border-2",
      className,
    )}
    {...rest}
  />
);

SmallSquareWithBorder.propTypes = {
  className: PropTypes.string,
};
