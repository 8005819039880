import { cloneElement, useRef, useState } from "react";
import { DropZone } from "../../DropZone";
import { If } from "../../If";
import { useEditorApi } from "../TrixEditorContext";

export const AttachFileForm = ({
  onClose,
  accept,
  dropTitle,
  helperText,
  children,
}) => {
  const { insertFile } = useEditorApi();
  const [key, setKey] = useState(0);

  const fileInputRef = useRef(null);
  const onClick = event => {
    // FIXME: Prevents triggering the file input when the key
    // enter is pressed on other form elements
    if (event?.detail === 0) {
      return;
    }

    event.preventDefault();
    fileInputRef.current.click();
  };
  const insertFiles = files => {
    onClose && onClose();
    for (const file of files) {
      insertFile(file);
    }
    setKey(key + 1);
  };

  const onSubmit = async event => {
    event.preventDefault();
    const files = event.target.files;
    insertFiles(files);
  };

  if (children) {
    children = cloneElement(children, { onClick: onClick });
  }

  return (
    <div className="file-upload" key={key}>
      <input
        ref={fileInputRef}
        onChange={onSubmit}
        type="file"
        style={{ display: "none" }}
        accept={accept}
      />
      <If condition={!!children}>{children ? children : ""}</If>
      <If condition={!children}>
        <DropZone
          onFileDrop={insertFiles}
          dropTitle={dropTitle}
          helperText={helperText}
        />
      </If>
    </div>
  );
};
