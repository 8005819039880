import { usePunditUserContext } from "@circle-react/contexts";

export const useIsProfileFieldsEnabled = () => {
  const { currentCommunity, isLoading } = usePunditUserContext();

  return !!(
    !isLoading && currentCommunity?.profile_fields_feature_flag_enabled
  );
};

export function useShouldShowProfileFieldsUpgradeModal() {
  const { currentCommunitySettings, currentCommunityMember } =
    usePunditUserContext();

  const isUpgradeFlowsBetaFlagEnabled =
    currentCommunitySettings?.upgrade_flows_for_profile_fields_enabled;
  const isProfileFieldsEnabled = useIsProfileFieldsEnabled();
  const isAdmin = currentCommunityMember?.is_admin;

  return Boolean(
    isUpgradeFlowsBetaFlagEnabled & !isProfileFieldsEnabled && isAdmin,
  );
}
