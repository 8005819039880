import { useEffect } from "react";
import { t } from "i18n-js";
import { isDowngrading } from "@/react/helpers/planHelpers";
import { usePunditUserContext } from "@circle-react/contexts";
import { usePlanStatus } from "@circle-react/hooks/usePlanStatus";
import type { Plan } from "@circle-react/types";
import { Modal } from "@circle-react-uikit/ModalV2";
import { Typography } from "@circle-react-uikit/Typography";
import { ContactSupport } from "../ContactSupport";
import { Loader } from "../Loader";
import { useCanDowngrade } from "../hooks/useCanDowngrade";
import { RestrictionWrapper } from "./RestrictionWrapper";

// todo: use Plan from react/types/Plan.ts
interface UpgradeErrorModalComponentProps {
  changePlan: (plan: Plan) => void;
  plan: Plan;
  hideModal: () => void;
}

const i18nRoot = "pricing_plans.upgrade_modal";

export const CanDowngrade = ({
  plan,
  changePlan,
  hideModal,
}: UpgradeErrorModalComponentProps) => {
  const { currentPlanTier } = usePlanStatus();
  const isDowngradingThePlan = isDowngrading(currentPlanTier, plan.tier);
  const { currentCommunity } = usePunditUserContext();
  const isSubscriptionCancelled = currentCommunity.subscription_cancelled;

  const { data, error, isLoading } = useCanDowngrade(plan.id);

  useEffect(() => {
    if (!isLoading && data && data?.can_downgrade) {
      changePlan(plan);
    }
  }, [data, isLoading, plan, changePlan]);

  if (isLoading) {
    return <Loader title={t([i18nRoot, "loading.validating_limits"])} />;
  }
  if (error) {
    return (
      <ContactSupport
        title={t(
          "pricing_plans.upgrade_modal.errors.limits_validation_error.title",
        )}
        errorMessage={t(
          "pricing_plans.upgrade_modal.errors.limits_validation_error.message",
        )}
      />
    );
  }

  const actionType = isDowngradingThePlan ? "downgrade" : "upgrade";

  return (
    <>
      {data && !data.can_downgrade && (
        <>
          <Modal.Header>
            <Modal.Title>
              {t(
                isSubscriptionCancelled
                  ? "plan_change.restrictions.plain_text.title_for_canceled_sub"
                  : "plan_change.restrictions.plain_text.title",
                { change_type: actionType },
              )}
            </Modal.Title>
            <Modal.HeaderActions>
              <Modal.CloseButton onClick={hideModal} />
            </Modal.HeaderActions>
          </Modal.Header>
          <Modal.Body className="mb-10 flex flex-col gap-y-2">
            {data.blocking_features && data.blocking_features.length === 0 ? (
              <Typography.BodyMd>
                {t(
                  "pricing_plans.upgrade_modal.errors.downgrade_check_error.message",
                )}
              </Typography.BodyMd>
            ) : (
              <>
                <Typography.BodyMd>
                  {t(
                    "plan_change.restrictions.plain_text.features_not_allowed",
                    {
                      plan: plan?.display_name,
                    },
                  )}
                </Typography.BodyMd>

                {!isSubscriptionCancelled && (
                  <Typography.BodyMd>
                    {t("plan_change.restrictions.plain_text.make_adjustments", {
                      change_type: actionType,
                    })}
                  </Typography.BodyMd>
                )}

                <RestrictionWrapper features={data.blocking_features} />

                {!isSubscriptionCancelled && (
                  <Typography.BodyMd>
                    {t("plan_change.restrictions.plain_text.do_not_care")}
                  </Typography.BodyMd>
                )}
              </>
            )}
          </Modal.Body>
        </>
      )}
    </>
  );
};
