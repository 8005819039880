import { useMemo } from "react";
import { uniqueId } from "lodash";
import { supportedLocalesOptions } from "@circle-react/config/Locales";
import { Form } from "@circle-react-uikit/Form";

export const LocaleField = ({ name = "locale" }) => {
  const id = useMemo(() => uniqueId("locale-"), []);

  return (
    <Form.Item
      id={id}
      name={name}
      hideDescription
      hideBorder
      translationRoot="profile_fields.form"
      fullWidth
      required
    >
      <Form.SelectNative name={name} options={supportedLocalesOptions} />
    </Form.Item>
  );
};
