import { FIELD_TYPES, FIELD_TYPES_TO_BACKEND_VALUE_PARAM } from "../constants";

const valuesByType = {
  [FIELD_TYPES.TEXT]: ({ text = "" }) => text,
  [FIELD_TYPES.TEXTAREA]: ({ textarea = "" }) => textarea,
  [FIELD_TYPES.CHECKBOX]: ({ community_member_choices = [] }) =>
    community_member_choices.map(({ profile_field_choice: { id } }) => id),
  [FIELD_TYPES.SELECT]: ({ community_member_choices = [] }) =>
    community_member_choices[0]?.profile_field_choice?.id ?? "",
};

export const constructProfileFieldDefaultValue = (fields = []) =>
  fields.map(
    ({
      id: fieldId,
      field_type: fieldType,
      community_member_profile_field: communityMemberProfileField,
    }) => {
      const name = FIELD_TYPES_TO_BACKEND_VALUE_PARAM[fieldType];
      const getValue = () => {
        const specificFieldTypeValue = valuesByType[fieldType]?.(
          communityMemberProfileField ?? {},
        );
        if (specificFieldTypeValue) {
          return specificFieldTypeValue;
        }
        return communityMemberProfileField?.text ?? "";
      };
      const id = communityMemberProfileField?.id ?? null;
      return { [name]: getValue(), profile_field_id: fieldId, id };
    },
  );

export const constructDefaultValue = data => {
  const { profile_fields } = data;

  const visibleProfileFields = profile_fields?.visible;

  const getPreferences = () => {
    const {
      messaging_enabled_by_member,
      visible_in_member_directory,
      make_my_email_public,
    } = data;
    return {
      prevent_members_from_messaging: !messaging_enabled_by_member,
      visible_in_member_directory,
      make_my_email_public: Boolean(make_my_email_public),
    };
  };
  return {
    ...data,
    preferences: getPreferences(),
    community_member_profile_fields_attributes:
      constructProfileFieldDefaultValue(visibleProfileFields),
  };
};
