import { useMemo } from "react";
import classNames from "classnames";
import { uniqueId } from "lodash";
import { useFormContext } from "react-hook-form";
import { LinkInput } from "@circle-react-shared/uikit/Form/LinkInput";
import { Form } from "@circle-react-uikit/Form";
import { FIELD_TYPES, getCustomFieldName } from "../../constants";
import type { FieldTypes } from "../../constants";
import { CustomCheckbox } from "./CustomCheckbox";
import { CustomSelect } from "./CustomSelect";
import { NumberInput } from "./NumberInput";

const i18nRoot = "profile_fields.form";
const prefix = "community_member_profile_fields_attributes";

const components = {
  [FIELD_TYPES.TEXT]: Form.Input,
  [FIELD_TYPES.LINK]: LinkInput,
  [FIELD_TYPES.NUMBER]: NumberInput,
  [FIELD_TYPES.TEXTAREA]: Form.TextArea,
  [FIELD_TYPES.SELECT]: CustomSelect,
  [FIELD_TYPES.CHECKBOX]: CustomCheckbox,
};

export interface CustomFieldProps {
  fieldType: FieldTypes;
  label?: string;
  hidden?: boolean;
  description?: string;
  placeholder?: string;
  index: number;
  dataTestId?: string;
  [key: string]: any;
}

interface PlaceholderProps {
  placeholder?: string;
  hidePlaceholder?: boolean;
}

export const CustomField = ({
  fieldType,
  label,
  description,
  placeholder,
  hidden = false,
  index,
  dataTestId,
  ...rest
}: CustomFieldProps) => {
  const Component: any = components[fieldType] || (() => null);
  const { register } = useFormContext();
  const id = useMemo(() => uniqueId(`custom-${fieldType}-field-`), [fieldType]);

  const placeholderProps: PlaceholderProps = {};
  if (placeholder) {
    placeholderProps.placeholder = placeholder;
  } else {
    placeholderProps.hidePlaceholder = true;
  }

  return (
    <div
      className={classNames({
        hidden: hidden,
      })}
      data-testid={dataTestId}
    >
      <input
        type="hidden"
        {...register(`${prefix}.${index}.profile_field_id`)}
      />
      <input type="hidden" {...register(`${prefix}.${index}.id`)} />
      <Form.Item
        id={id}
        name={getCustomFieldName({ fieldType, index })}
        hideDescription
        hideBorder
        translationRoot={i18nRoot}
        label={label}
        tooltipText={description}
        {...placeholderProps}
        {...rest}
      >
        <Component {...rest} />
      </Form.Item>
    </div>
  );
};
