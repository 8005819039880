import { t } from "i18n-js";
import type { Basic } from "unsplash-js/src/methods/photos/types";
import { unsplashCreateApi } from "@circle-react-shared/Unsplash/unsplashCreateApi";
import type { Area } from "./Cropper";

export const readURL = (file: File): Promise<string | undefined> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = event => {
      if (
        !event.target ||
        !event.target.result ||
        !(typeof event.target.result === "string")
      ) {
        return resolve(undefined);
      }

      return resolve(event.target.result);
    };
    reader.onerror = event => {
      reject(event);
    };
    reader.readAsDataURL(file);
  });

const acceptedImageTypes = ["image/gif", "image/jpeg", "image/png"];

export const checkIsImageFile = (fileType: string | null) => {
  if (!fileType) return false;
  return acceptedImageTypes.includes(fileType);
};

export const createRandomName = () =>
  Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, "")
    .slice(0, 5);

export const searchUnsplash = async (query: string) => {
  try {
    const trimmedQuery = query.trim();

    const search = await unsplashCreateApi.search.getPhotos({
      query: trimmedQuery,
      page: 1,
      perPage: 30,
      orientation: "landscape",
    });

    const results = search.response?.results || [];
    if (query.length >= 1 && results.length >= 1) {
      return results;
    }

    return [];
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const downloadPhoto = (item: Basic) =>
  unsplashCreateApi.photos.trackDownload({
    downloadLocation: item.links.download_location,
  });

export const isImageURL = async (url: string) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(t("image_upload.embed_network_error"));
    }
    const contentType = response.headers.get("content-type");
    if (!checkIsImageFile(contentType)) {
      throw new Error(t("image_upload.invalid_file_type_error"));
    }
  } catch {
    throw new Error(t("image_upload.embed_error"));
  }
};

export const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => {
      resolve(image);
    });
    image.addEventListener("error", () => {
      reject(new Error(t("image_upload.embed_error")));
    });
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  });

export const getCroppedImage = async (
  imageSrc: string,
  cropArea: Area,
  fileName: string,
  fileType: string,
): Promise<File> => {
  const canvas = document.createElement("canvas");
  const canvasContext = canvas.getContext("2d");

  if (!canvasContext) {
    throw new Error(t("image_upload.canvas_context_error"));
  }

  canvas.width = cropArea.width;
  canvas.height = cropArea.height;

  const elementToDraw = await createImage(imageSrc);
  const sourceTopLeftCornerX = cropArea.x;
  const sourceTopLeftCornerY = cropArea.y;
  const drawAreaWidth = cropArea.width;
  const drawAreaHeight = cropArea.height;
  const destinationTopLeftCornerX = 0;
  const destinationTopLeftCornerY = 0;
  const canvasWidth = canvas.width;
  const canvasHeight = canvas.height;

  canvasContext.drawImage(
    elementToDraw,
    sourceTopLeftCornerX,
    sourceTopLeftCornerY,
    drawAreaWidth,
    drawAreaHeight,
    destinationTopLeftCornerX,
    destinationTopLeftCornerY,
    canvasWidth,
    canvasHeight,
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      if (!blob) {
        reject(null);
        return;
      }
      try {
        const file = new File([blob], fileName, { type: fileType });
        resolve(file);
      } catch (error: unknown) {
        reject(error);
      }
    }, fileType);
  });
};
