import { useQuery } from "react-query";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { useIsProfileFieldsEnabled } from "@circle-react-shared/ProfileFields";
import type { FieldTypes } from "@circle-react-shared/ProfileFields/constants";

export const SEARCHABLE_PROFILE_FIELDS_LIST_KEY = "searchable-profile-fields";

interface ProfileField {
  id: number;
  label: string;
  field_type: FieldTypes;
}

export const useProfileFieldsList = () => {
  const isProfileFieldsEnabled = useIsProfileFieldsEnabled();

  const getOptions = useQuery<ProfileField[], unknown>(
    [SEARCHABLE_PROFILE_FIELDS_LIST_KEY],
    async () => reactQueryGet(internalApi.profileFields.searchableList()),
    {
      enabled: !!isProfileFieldsEnabled,
    },
  );

  return {
    getOptions,
  };
};
