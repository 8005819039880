import * as PropTypes from "prop-types";

// Main SVG wrapper component
const SvgWrap = ({
  width,
  height,
  viewBox,
  children,
  fill,
  stroke,
  strokeWidth,
  strokeLinecap,
  strokeLinejoin,
  dOne,
  dTwo,
  dThree,
  dFour,
  dFive,
  dSix,
}) => {
  const paths = [dOne, dTwo, dThree, dFour, dFive, dSix];

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {paths.map((path, i) => {
        if (path)
          return (
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d={path}
              fill={fill === "" ? "" : "#8D9CAE"}
              key={i}
              stroke={stroke}
              strokeWidth={strokeWidth}
              strokeLinecap={strokeLinecap}
              strokeLinejoin={strokeLinejoin}
            />
          );
      })}
      {children}
    </svg>
  );
};

// Individual icons with specified size & paths
export const Bold = () => (
  <SvgWrap
    width="10"
    height="13"
    viewBox="0 0 10 13"
    dOne="M5.48583 0H1.11336C1.07493 0 1.03695 0.00195996 0.999526 0.00578599C0.438107 0.0631765 0 0.540434 0 1.12069V6.01097V11.8793C0 12.4983 0.498468 13 1.11336 13H5.97166C8.19659 13 10 11.1847 10 8.94514C10 7.59944 9.34889 6.40694 8.34645 5.66939C8.7752 5.07967 9.02834 4.35247 9.02834 3.56583C9.02834 1.59668 7.4421 0 5.48583 0ZM2.22604 7.13166H5.48583L5.48839 7.1316L5.97166 7.13166C6.96681 7.13166 7.77328 7.94344 7.77328 8.94514C7.77328 9.94684 6.96681 10.7586 5.97166 10.7586H2.22604V7.13166ZM5.48583 4.89028C6.21231 4.89028 6.80162 4.2971 6.80162 3.56583C6.80162 2.87758 6.27961 2.31164 5.61252 2.24744L5.48583 2.24138H2.22672V4.89028H5.48583Z"
  />
);
export const Code = () => (
  <SvgWrap
    width="16"
    height="12"
    viewBox="0 0 16 12"
    dOne="M3.8 3.30005L1 6.10005L3.8 8.90005"
    dTwo="M12.2002 3.30005L15.0002 6.10005L12.2002 8.90005"
    dThree="M6.76562 11.039L9.27293 1.00977L6.76562 11.039Z"
    fill=""
    stroke="#8D9CAE"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
);
export const Italic = () => (
  <SvgWrap
    width="8"
    height="13"
    viewBox="0 0 8 13"
    dOne="M4 1.50251H4.60125L1.79606 11.4975H0.773109L0.668203 11.5043C0.290848 11.5541 0 11.8684 0 12.2487C0 12.6637 0.346133 13 0.773109 13H2.36978C2.3813 13.0003 2.39279 13.0002 2.40424 13H4L4.10491 12.9931C4.48226 12.9434 4.77311 12.6291 4.77311 12.2487C4.77311 11.8338 4.42698 11.4975 4 11.4975H3.39875L6.20394 1.50251H7.22689L7.3318 1.49565C7.70915 1.44591 8 1.13159 8 0.751256C8 0.336349 7.65387 0 7.22689 0H5.63024C5.61871 -0.000252011 5.6072 -0.000250653 5.59574 0H4L3.89509 0.00685808C3.51774 0.0566037 3.22689 0.370925 3.22689 0.751256C3.22689 1.16616 3.57302 1.50251 4 1.50251Z"
  />
);
export const Link = () => (
  <SvgWrap
    width="15"
    height="15"
    viewBox="0 0 15 15"
    dOne="M8.41 6.58997V6.58997C9.71 7.88997 9.71 9.90497 8.41 11.205L6.59 13.025C5.29 14.325 3.275 14.325 1.975 13.025C0.675 11.725 0.675 9.70997 1.975 8.40997L3.6 6.84997"
    dTwo="M6.59023 8.41V8.41C5.29023 7.11 5.29023 5.095 6.59023 3.795L8.41023 1.975C9.71023 0.675 11.7252 0.675 13.0252 1.975C14.3252 3.275 14.3252 5.29 13.0252 6.59L11.4002 8.15"
    fill=""
    stroke="#8D9CAE"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
);
export const List = () => (
  <SvgWrap
    width="14"
    height="12"
    viewBox="0 0 14 12"
    dOne="M1.85714 0.928571C1.85714 1.44161 1.44161 1.85714 0.928571 1.85714C0.415536 1.85714 0 1.44161 0 0.928571C0 0.415536 0.415536 0 0.928571 0C1.44161 0 1.85714 0.415536 1.85714 0.928571Z"
  >
    <path
      d="M4.33301 0.928589H12.9997"
      stroke="#8D9CAE"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.85714 5.88096C1.85714 6.394 1.44161 6.80954 0.928571 6.80954C0.415536 6.80954 0 6.394 0 5.88096C0 5.36793 0.415536 4.95239 0.928571 4.95239C1.44161 4.95239 1.85714 5.36793 1.85714 5.88096Z"
      fill="#8D9CAE"
    />
    <path
      d="M4.33301 5.88098H12.9997"
      stroke="#8D9CAE"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.85714 10.8334C1.85714 11.3464 1.44161 11.7619 0.928571 11.7619C0.415536 11.7619 0 11.3464 0 10.8334C0 10.3203 0.415536 9.90479 0.928571 9.90479C1.44161 9.90479 1.85714 10.3203 1.85714 10.8334Z"
      fill="#8D9CAE"
    />
    <path
      d="M4.33301 10.8334H12.9997"
      stroke="#8D9CAE"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </SvgWrap>
);
export const NumList = () => (
  <SvgWrap
    width="15"
    height="14"
    viewBox="0 0 15 14"
    dOne="M0.364258 3.1567V2.72391L0.835388 2.68557C0.912084 2.68009 0.92304 2.65817 0.92304 2.576V0.663478C0.92304 0.597739 0.907214 0.56487 0.851823 0.548435L0.375214 0.449826L0.435475 0H1.75087V2.576C1.75087 2.66365 1.75634 2.68009 1.83852 2.68557L2.28774 2.72391V3.1567H0.364258Z"
    dTwo="M0.0793635 8.06736V7.55788L0.814059 6.83962C1.36736 6.30275 1.60293 6.08362 1.60293 5.77136C1.60293 5.56867 1.50432 5.42075 1.21397 5.42075C0.912059 5.42075 0.786059 5.51936 0.786059 5.90832L0.0410156 5.84258C0.0464939 4.97153 0.709363 4.82849 1.28458 4.82849C2.13432 4.82849 2.43562 5.19006 2.43562 5.70562C2.43562 6.22058 2.07954 6.56023 1.67962 6.94371L1.05997 7.53597H1.86589C1.92067 7.53597 1.94258 7.52501 1.95354 7.47023L2.03023 7.03745H2.61093V8.06736H0.0793635Z"
    dThree="M1.92896 11.2573C2.39522 11.3286 2.65209 11.608 2.65209 12.101C2.65209 12.8247 2.10974 13.1644 1.32635 13.1644C0.773043 13.1644 0.28 12.9672 0 12.6604L0.438261 12.1783C0.647043 12.381 0.876522 12.5393 1.26061 12.5393C1.56739 12.5393 1.83035 12.4303 1.83035 12.0462C1.83035 11.712 1.62217 11.5751 1.28252 11.5751C1.14557 11.5751 1.04148 11.586 0.876522 11.6134V11.0656L1.14557 11.0327C1.54 10.9834 1.75365 10.7917 1.75365 10.4411C1.75365 10.2323 1.66052 10.0631 1.35374 10.0631C1.05791 10.0631 0.915478 10.1617 0.915478 10.5507L0.164957 10.4843C0.164957 9.61874 0.81687 9.47083 1.39209 9.47083C2.23574 9.47083 2.55957 9.79404 2.55957 10.3808C2.55957 10.841 2.27409 11.1313 1.92896 11.2354V11.2573Z"
  >
    <path
      d="M4.28516 1.57849H13.4156"
      stroke="#8D9CAE"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M4.28516 6.448H13.4156"
      stroke="#8D9CAE"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M4.28516 11.3176H13.4156"
      stroke="#8D9CAE"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </SvgWrap>
);
export const Quote = () => (
  <SvgWrap
    width="15"
    height="12"
    viewBox="0 0 15 12"
    dOne="M1.59082 1H13.9999"
    dTwo="M1.59139 11.2267L1.66358 5.31824"
    dThree="M5.72754 5.61369H14.0003"
    dFour="M5.72754 10.9318H11.6366"
    stroke="#8D9CAE"
    strokeWidth="1.5"
    strokeLinecap="round"
  />
);
export const Strikethrough = () => (
  <SvgWrap
    width="15"
    height="14"
    viewBox="0 0 15 14"
    dOne="M11.5323 3.15165C11.27 1.2493 9.78733 0 7.76961 0H6.53884L6.35102 0.0045072C4.35973 0.100288 2.76961 1.7107 2.76961 3.69231C2.76961 5.89758 3.92624 6.61807 6.99027 7.36492L7.67827 7.53689C7.91526 7.59864 8.10006 7.65096 8.26984 7.7049L8.56725 7.80612C8.92672 7.93641 9.32374 7.75063 9.45404 7.39116C9.58433 7.03169 9.39855 6.63467 9.03908 6.50437C8.69397 6.37928 8.39144 6.28935 7.89205 6.16208L6.84676 5.90107C4.75487 5.35544 4.15422 4.90072 4.15422 3.69231C4.15422 2.42144 5.21763 1.38462 6.53884 1.38462H7.76961L7.94128 1.38884C9.18131 1.45061 10.0015 2.18604 10.1607 3.34079C10.2129 3.71956 10.5623 3.98427 10.9411 3.93204C11.3199 3.87981 11.5846 3.53042 11.5323 3.15165ZM11.5388 9.69231C11.5388 9.30996 11.2289 9 10.8465 9C10.4642 9 10.1542 9.30996 10.1542 9.69231L10.1492 9.84364C10.0687 11.0445 9.03797 12 7.76961 12H6.53884L6.37338 11.9951C5.17453 11.9235 4.30011 11.0747 4.14863 9.89036C4.10011 9.5111 3.75334 9.24298 3.37407 9.29149C2.99481 9.34 2.72669 9.68678 2.7752 10.066C3.02042 11.9831 4.54576 13.3846 6.53884 13.3846H7.76961L7.95743 13.3801C9.94872 13.2843 11.5388 11.6739 11.5388 9.69231Z"
    dTwo="M13.6154 6.46155C13.9977 6.46155 14.3077 6.7715 14.3077 7.15386C14.3077 7.50434 14.0472 7.794 13.7093 7.83984L13.6154 7.84616H0.692308C0.309957 7.84616 0 7.53621 0 7.15386C0 6.80337 0.26045 6.51371 0.598366 6.46787L0.692308 6.46155H13.6154Z"
  />
);
export const Attachment = () => (
  <SvgWrap
    width="19"
    height="20"
    viewBox="0 0 19 20"
    dOne="M16.2507 9.22777L10.9281 15.1392C9.39517 16.8416 6.66831 16.8697 4.81617 15.202C2.96402 13.5343 2.70687 10.8195 4.23978 9.11698L8.9237 3.91496C9.98822 2.73269 11.8688 2.71335 13.155 3.87146C14.4412 5.02958 14.6186 6.90188 13.5541 8.08416L9.29596 12.8133C8.69982 13.4753 7.6655 13.486 6.94522 12.8374C6.22494 12.1889 6.1274 11.1591 6.72353 10.497L10.3429 6.4773"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
);
export const HTwo = () => (
  <SvgWrap
    width="20"
    height="15"
    viewBox="0 0 20 15"
    dOne="M0 0H6.41485V1.06838H5.42198C4.805 1.06838 4.30484 1.56854 4.30484 2.18551V6.42305H10.7187V2.18551C10.7187 1.56854 10.2185 1.06838 9.60157 1.06838H8.58509V0H15V1.06838H13.9945C13.3775 1.06838 12.8774 1.56854 12.8774 2.18551V12.8037C12.8774 13.4206 13.3775 13.9208 13.9945 13.9208H15V15H8.58509V13.9208H9.60157C10.2185 13.9208 10.7187 13.4206 10.7187 12.8037V7.5H4.30484V12.8037C4.30484 13.4206 4.805 13.9208 5.42198 13.9208H6.41485V15H0V13.9208H1.031C1.64798 13.9208 2.14814 13.4206 2.14814 12.8037V2.18552C2.14814 1.56854 1.64798 1.06838 1.031 1.06838H0V0ZM19.1 19.5C19.5971 19.5 20 19.9029 20 20.4C20 20.8971 19.5971 21.3 19.1 21.3H0.899999C0.402943 21.3 0 20.8971 0 20.4C0 19.9029 0.402944 19.5 0.9 19.5H19.1ZM17 24.9C17 24.4029 16.5971 24 16.1 24H0.9C0.402944 24 0 24.4029 0 24.9C0 25.3971 0.402943 25.8 0.899999 25.8H16.1C16.5971 25.8 17 25.3971 17 24.9Z"
    dTwo="M16.0597 14.2135L17.2032 13.1047C18.0644 12.2759 18.4311 11.9376 18.4311 11.4556C18.4311 11.1426 18.2776 10.9143 17.8257 10.9143C17.3558 10.9143 17.1596 11.0665 17.1596 11.667L16 11.5655C16.0085 10.2208 17.0403 10 17.9356 10C19.2582 10 19.7271 10.5582 19.7271 11.3541C19.7271 12.149 19.1729 12.6734 18.5505 13.2654L17.7035 14.0797H18.6962C18.9171 14.0797 19.0962 13.9006 19.0962 13.6797V13.4101H20V15H16.0597V14.2135Z"
  />
);
export const HThree = () => (
  <SvgWrap
    width="20"
    height="15"
    viewBox="0 0 20 15"
    dOne="M0 0H6.41485V1.06838H5.42198C4.805 1.06838 4.30484 1.56854 4.30484 2.18551V6.42305H10.7187V2.18551C10.7187 1.56854 10.2185 1.06838 9.60157 1.06838H8.58509V0H15V1.06838H13.9945C13.3775 1.06838 12.8774 1.56854 12.8774 2.18551V12.8037C12.8774 13.4206 13.3775 13.9208 13.9945 13.9208H15V15H8.58509V13.9208H9.60157C10.2185 13.9208 10.7187 13.4206 10.7187 12.8037V7.5H4.30484V12.8037C4.30484 13.4206 4.805 13.9208 5.42198 13.9208H6.41485V15H0V13.9208H1.031C1.64798 13.9208 2.14814 13.4206 2.14814 12.8037V2.18552C2.14814 1.56854 1.64798 1.06838 1.031 1.06838H0V0ZM19.1 19.5C19.5971 19.5 20 19.9029 20 20.4C20 20.8971 19.5971 21.3 19.1 21.3H0.899999C0.402943 21.3 0 20.8971 0 20.4C0 19.9029 0.402944 19.5 0.9 19.5H19.1ZM17 24.9C17 24.4029 16.5971 24 16.1 24H0.9C0.402944 24 0 24.4029 0 24.9C0 25.3971 0.402943 25.8 0.899999 25.8H16.1C16.5971 25.8 17 25.3971 17 24.9Z"
    dTwo="M18.9093 12.1603C19.6126 12.2663 20 12.6824 20 13.4165C20 14.4942 19.182 15 18.0005 15C17.1659 15 16.4223 14.7063 16 14.2495L16.661 13.5316C16.9759 13.8335 17.322 14.0691 17.9013 14.0691C18.364 14.0691 18.7606 13.9069 18.7606 13.335C18.7606 12.8373 18.4466 12.6334 17.9344 12.6334C17.7278 12.6334 17.5708 12.6497 17.322 12.6905V11.8748L17.7278 11.8258C18.3227 11.7524 18.6449 11.4669 18.6449 10.9448C18.6449 10.6339 18.5045 10.3819 18.0418 10.3819C17.5956 10.3819 17.3808 10.5288 17.3808 11.1079L16.2488 11.0091C16.2488 9.72025 17.232 9.5 18.0996 9.5C19.372 9.5 19.8605 9.9813 19.8605 10.8551C19.8605 11.5403 19.4299 11.9726 18.9093 12.1276V12.1603Z"
  />
);

SvgWrap.propTypes = {
  dOne: PropTypes.string.isRequired,
  dTwo: PropTypes.string,
  dThree: PropTypes.string,
  dFour: PropTypes.string,
  dFive: PropTypes.string,
  dSix: PropTypes.string,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.string,
  strokeLinecap: PropTypes.string,
  strokeLinejoin: PropTypes.string,
  children: PropTypes.node,
};
