import { DirectUpload } from "@rails/activestorage";
import type { Blob } from "@rails/activestorage";
import {
  actionTextAttachmentsPath,
  activeStorageBlobUrl,
  directUploadPath,
} from "@circle-react/helpers/urlHelpers";

interface DirectUploadAttributes extends Blob {
  attachable_sgid: string;
  created_at: string;
  id: number;
  key: string;
  metadata: Record<string, string>;
  service_name: string;
  updated_at: string;
}

type UploadProgressCallback = (progress: number, fileName: string) => void;

type AfterCompleteCallback = (
  blobUrl: string,
  signedId: string,
  contentType: string,
  inputFieldName: string,
) => void;

const isDirectUploadAttributes = (
  attributes: Blob | DirectUploadAttributes,
): attributes is DirectUploadAttributes => "attachable_sgid" in attributes;

export class Uploader {
  file: File;
  afterCompleteCallback: AfterCompleteCallback;
  uploadProgress: UploadProgressCallback;
  formClass: string;
  inputFieldName: string;
  upload: DirectUpload;

  constructor(
    file: File,
    afterCompleteCallback: AfterCompleteCallback,
    formClass: string,
    inputFieldName: string,
    uploadProgress: UploadProgressCallback,
  ) {
    this.file = file;
    this.afterCompleteCallback = afterCompleteCallback;
    this.uploadProgress = uploadProgress;
    this.formClass = formClass;
    this.inputFieldName = inputFieldName;
    this.upload = new DirectUpload(this.file, directUploadPath(), this);
  }

  start(): void {
    const boundFunction = this.directUploadDidComplete.bind(this);
    const directUploadDidComplete = (
      error: Error | null,
      attributes: Blob | DirectUploadAttributes,
    ) => {
      void boundFunction(error, attributes);
    };
    this.upload.create(directUploadDidComplete);
  }

  async directUploadDidComplete(
    error: Error | null,
    attributes: Blob | DirectUploadAttributes,
  ): Promise<void> {
    const signedId = attributes.signed_id;
    const fileName = attributes.filename;
    const contentType = attributes.content_type;

    if (error) {
      if (error instanceof Error) {
        throw new Error(`Direct upload failed: ${error.message}`);
      } else {
        throw error;
      }
    }

    if (isDirectUploadAttributes(attributes)) {
      await this.getAttachmentContent(attributes.attachable_sgid);
    }

    if (this.formClass !== "" && this.inputFieldName !== "") {
      const hiddenField = document.createElement("input");
      const inputFieldName = this.inputFieldName;

      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("value", signedId);
      hiddenField.name = inputFieldName;

      document.querySelector(`.${this.formClass}`)?.appendChild(hiddenField);
    }

    const blobUrl = this.createBlobUrl(signedId, fileName);
    if (this.afterCompleteCallback) {
      this.afterCompleteCallback(
        blobUrl,
        signedId,
        contentType,
        this.inputFieldName,
      );
    }
  }

  async getAttachmentContent(sgid: string): Promise<string> {
    const response = await fetch(actionTextAttachmentsPath(sgid));
    return await response.text();
  }

  createBlobUrl(signedId: string, filename: string): string {
    return activeStorageBlobUrl({ signed_id: signedId, filename });
  }

  directUploadWillStoreFileWithXHR(xhr: XMLHttpRequest): void {
    xhr.upload.addEventListener("progress", (event: ProgressEvent) => {
      const progress = Math.floor((event.loaded / event.total) * 100);
      this.uploadProgress(progress, this.file.name);
    });
  }
}
