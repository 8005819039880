import { t } from "i18n-js";
import { Divider } from "./Divider";
import { LineItem } from "./LineItem";
import { LineItemSm } from "./LineItemSm";

export const Details = ({ prorationData }: any) => (
  <>
    <LineItem
      title={prorationData.plan_preview_name}
      amount={prorationData.plan_display_amount}
    />
    <Divider />
    {/* todo: expandable is not working */}
    {prorationData.display_recurring_plan_charges_description && (
      <>
        <LineItem
          title={t("pricing_plans.change_plan.prorated_charges_debit")}
          description={prorationData.display_recurring_plan_charges_description}
          amount={prorationData.display_recurring_plan_charges_amount}
          semibold
        />
        <Divider />
      </>
    )}

    {prorationData.display_prorated_charges_debit_description && (
      <>
        <LineItem
          title={t("pricing_plans.change_plan.prorated_charges_debit")}
          description={prorationData.display_prorated_charges_debit_description}
          amount={prorationData.display_prorated_charges_debit_amount}
          semibold
        />
        <Divider />
      </>
    )}
    {prorationData.display_prorated_charges_credit_description && (
      <>
        <LineItem
          title={t("pricing_plans.change_plan.prorated_charges_credit")}
          description={
            prorationData.display_prorated_charges_credit_description
          }
          amount={prorationData.display_prorated_charges_credit_amount}
          semibold
        />
        <Divider />
      </>
    )}
    <LineItemSm
      title={t("pricing_plans.change_plan.subtotal")}
      amount={prorationData.display_subtotal}
    />
    {prorationData.display_tax && (
      <LineItemSm
        title={t("pricing_plans.change_plan.tax")}
        amount={prorationData.display_tax}
      />
    )}
    {prorationData.display_total_discount && (
      <LineItemSm
        title={t("pricing_plans.change_plan.total_discount")}
        amount={prorationData.display_total_discount}
      />
    )}
    {prorationData.display_applied_balance && (
      <LineItemSm
        title={t("pricing_plans.change_plan.applied_balance")}
        amount={prorationData.display_applied_balance}
      />
    )}
    <Divider />
  </>
);
