import Cropper from "react-easy-crop";
import { CropSlider } from "./CropSlider";
import { ImageError } from "./ImageError";

export interface Point {
  x: number;
  y: number;
}

export interface Area {
  width: number;
  height: number;
  x: number;
  y: number;
}

export interface ImageCropperProps {
  imageError?: string;
  file?: string;
  crop: Point;
  aspectRatio?: number;
  zoom: number;
  setCrop: (crop: Point) => void;
  setZoom: (zoom: number) => void;
  onCropComplete: (croppedArea: Area, croppedAreaPixels: Area) => void;
}

export const ImageCropper = ({
  imageError,
  file,
  crop,
  aspectRatio,
  zoom,
  setCrop,
  setZoom,
  onCropComplete,
}: ImageCropperProps) => (
  <div className="crop-content">
    <ImageError error={imageError} />
    <div className="crop-content__box">
      <Cropper
        image={file}
        crop={crop}
        aspect={aspectRatio}
        zoom={zoom}
        onCropChange={setCrop}
        onZoomChange={setZoom}
        onCropComplete={onCropComplete}
      />
    </div>
    <CropSlider
      value={zoom}
      onChange={(_, zoom) => {
        if (Array.isArray(zoom) && typeof zoom[0] === "number") {
          setZoom(zoom[0]);
          return;
        }

        if (typeof zoom === "number") {
          setZoom(zoom);
        }
      }}
    />
  </div>
);
