import { Form } from "@circle-react-uikit/Form";

interface Choice {
  id: number;
  value: string;
}

export interface CustomCheckboxProps {
  name: string;
  rules?: any;
  choices: Choice[];
}

export const CustomCheckbox = ({
  name,
  rules,
  choices,
}: CustomCheckboxProps) => (
  <Form.CheckboxGroup
    options={choices.map(({ id, value }) => ({ label: value, value: id }))}
    name={name}
    rules={rules}
  />
);
