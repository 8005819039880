import type { ReactElement } from "react";
import { Form } from "@circle-react-uikit/Form";
import type { FormInputProps } from "@circle-react-uikit/Form/FormInput";
import { NUMERIC_FORMATS } from "../../constants";
import type { NumericFormats } from "../../constants";
import { AffixText } from "./AffixText";

interface NumberOptions {
  format?: NumericFormats;
  decimal?: number;
  position?: string;
  text?: string;
}

interface NumberOptionsProps {
  numberOptions: NumberOptions;
}

export const getPlaceholderPreview = ({
  numberOptions: { format, decimal },
}: NumberOptionsProps) => {
  if (format === NUMERIC_FORMATS.UNFORMATTED) {
    return 0;
  }

  let numericPreview = "0";

  if (decimal && decimal > 0) {
    const zeros = "0".padEnd(decimal, "0");
    numericPreview += `.${zeros}`;
  }

  return numericPreview;
};

interface GetAffixReturn {
  prefix?: ReactElement;
  suffix?: ReactElement;
}

const getAffix = ({
  numberOptions: { format, position, text = "" },
}: NumberOptionsProps): GetAffixReturn => {
  if (format === NUMERIC_FORMATS.PERCENT) {
    return {
      suffix: <AffixText text="%" />,
    };
  }

  if (format !== NUMERIC_FORMATS.CUSTOM) {
    return {};
  }

  if (position === "left") {
    return {
      prefix: <AffixText className="px-4 py-3" text={text} />,
    };
  }

  return {
    suffix: <AffixText text={text} />,
  };
};

export interface NumberInputProps extends FormInputProps {
  number_options: NumberOptions;
}

export const NumberInput = ({
  number_options: numberOptions,
  ...rest
}: NumberInputProps) => {
  const placeholder = getPlaceholderPreview({ numberOptions });
  const affix = getAffix({ numberOptions });

  const handleTransform = (event: any) => {
    const { format, decimal: allowedDecimalPlaces } = numberOptions;
    if (format === NUMERIC_FORMATS.UNFORMATTED) return true;

    const input = event.target;
    const { value } = input;

    if (!Number.isNaN(value)) {
      const decimalIndex = value.indexOf(".");
      if (decimalIndex === -1) {
        return true;
      }

      if (allowedDecimalPlaces !== undefined && allowedDecimalPlaces === 0) {
        input.value = value.substring(0, decimalIndex);
      } else if (
        allowedDecimalPlaces !== undefined &&
        value.length - decimalIndex > allowedDecimalPlaces + 1
      ) {
        input.value = value.substring(
          0,
          decimalIndex + allowedDecimalPlaces + 1,
        );
      }
    }

    return undefined;
  };

  return (
    <Form.Input
      {...rest}
      placeholder={placeholder}
      inputmode="decimal"
      type="text"
      onInput={handleTransform}
      {...affix}
    />
  );
};
