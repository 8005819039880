import PropTypes from "prop-types";
import { t } from "i18n-js";
import noop from "lodash/noop";
import { ImageUploadField } from "@circle-react/components/CommunityMember/EditCurrentProfile/Form/ImageUpload";
import { EmptyAvatar } from "@circle-react-shared/Placeholders";
import {
  CustomFieldsList,
  Permissions,
} from "@circle-react-shared/ProfileFields";
import { Button } from "@circle-react-uikit/Button";
import { Form } from "@circle-react-uikit/Form";
import useStaticFieldConfig from "../StaticContent/useStaticFieldConfig";
import { getValidationSchema } from "../formUtils";

const defaultFormValue = {
  preferences: {
    visible_in_member_directory: true,
  },
};

export const SignUpForm = ({
  fields = {},
  defaultValues = defaultFormValue,
  onSubmit = noop,
  isPreview = true,
  isLoading,
  dataTestId,
}) => {
  const { visibleFields = [] } = fields;
  const shouldShowSkipButton = !visibleFields.some(({ required }) => required);

  const { STATIC_FORM_COMPONENTS } = useStaticFieldConfig();

  return (
    <Form
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      noValidate
      validationSchema={getValidationSchema(visibleFields)}
    >
      <div className="flex flex-col gap-y-8" data-testid={dataTestId}>
        <div>
          <div className="mb-4">
            {isPreview ? (
              <EmptyAvatar size="lg" />
            ) : (
              <ImageUploadField title={t("upload_a_profile_photo")} />
            )}
          </div>
          {STATIC_FORM_COMPONENTS.map(({ id, component: Component }) => (
            <Component key={id} />
          ))}
          <CustomFieldsList fields={visibleFields} />
          <Permissions />
        </div>
        {!isPreview && (
          <div className="flex justify-end gap-x-3">
            {shouldShowSkipButton && (
              <Button
                type="submit"
                disabled={isLoading}
                variant="secondary-light"
              >
                {isLoading ? t("saving") : t("skip")}
              </Button>
            )}
            <Button type="submit" disabled={isLoading}>
              {isLoading ? t("saving") : t("continue")}
            </Button>
          </div>
        )}
      </div>
    </Form>
  );
};

SignUpForm.propTypes = {
  fields: PropTypes.shape({
    visibleFields: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
  isPreview: PropTypes.bool,
  isLoading: PropTypes.bool,
  dataTestId: PropTypes.string,
};
