import { Body } from "./Body";
import { Footer } from "./Footer";
import { FooterForm } from "./FooterForm";
import { Root } from "./Root";

export const ProfileModalTemplate = Object.assign(
  {},
  {
    Root,
    Body,
    Footer,
    FooterForm,
  },
);
