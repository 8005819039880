import type { ChangeEvent, MouseEvent } from "react";
import { t } from "i18n-js";
import type { Basic } from "unsplash-js/src/methods/photos/types";
import { UnsplashTab } from "@circle-react-shared/Unsplash/UnsplashTab";
import { Tab } from "@circle-react-uikit/TabV2";
import { UnsplashTabContent } from "../Unsplash/UnsplashTabContent";
import { EmbedUpload } from "./EmbedUpload";
import { FileUpload } from "./FileUpload";

export interface FileUploadMethodsProps {
  addEmbed: (event: MouseEvent<HTMLButtonElement>) => void;
  addUnsplash: (item: Basic) => void;
  aspectRatioHelpText?: string;
  embedUrl?: string;
  hideEmbedUrl?: boolean;
  hideUnsplash?: boolean;
  imageError?: string;
  insertFiles: (files: FileList | null) => void;
  onChangeEmbedUrl: (event: ChangeEvent<HTMLInputElement>) => void;
  searchUnsplash: (value: string) => void;
  unsplashResults: Basic[];
}

export const FileUploadMethods = ({
  addEmbed,
  addUnsplash,
  aspectRatioHelpText,
  embedUrl,
  hideEmbedUrl,
  hideUnsplash,
  imageError,
  insertFiles,
  onChangeEmbedUrl,
  searchUnsplash,
  unsplashResults,
}: FileUploadMethodsProps) => (
  <Tab.Group>
    <Tab.List className="border-primary border-b">
      <Tab>{t("upload")}</Tab>
      {!hideUnsplash && (
        <Tab>
          <UnsplashTab />
        </Tab>
      )}
      {!hideEmbedUrl && <Tab>{t("image_upload.embed")}</Tab>}
    </Tab.List>
    <Tab.Panels className="!h-[calc(100vh-57px-72px)] md:!h-full">
      <Tab.Panel>
        <FileUpload
          imageError={imageError}
          insertFiles={insertFiles}
          aspectRatioHelpText={aspectRatioHelpText}
        />
      </Tab.Panel>
      {!hideUnsplash && (
        <Tab.Panel>
          <UnsplashTabContent
            unsplashResults={unsplashResults}
            searchUnsplash={searchUnsplash}
            addUnsplash={addUnsplash}
          />
        </Tab.Panel>
      )}
      {!hideEmbedUrl && (
        <Tab.Panel>
          <EmbedUpload
            imageError={imageError}
            embedUrl={embedUrl}
            onChangeEmbedUrl={onChangeEmbedUrl}
            addEmbed={addEmbed}
          />
        </Tab.Panel>
      )}
    </Tab.Panels>
  </Tab.Group>
);
