import { t } from "i18n-js";
import { Tabs } from "@circle-react-uikit/Tabs";
import { UnsplashTab } from "../../Unsplash/UnsplashTab";
import { AttachFileForm } from "../AttachFileForm";
import { EmbedForm } from "../EmbedForm";
import { UnsplashForm } from "../UnsplashForm";

export const ImageSelector = (
  { embedPlaceholder, embedDesc, dropTitle, helperText, embedBtn, onClose },
  ...props
) => (
  <Tabs defaultValue="Upload" variant="underline">
    <div label={t("upload")}>
      <div className="pt-3">
        <AttachFileForm
          onClose={onClose}
          dropTitle={dropTitle}
          helperText={helperText}
          {...props}
          accept="image/*"
        />
      </div>
    </div>
    <div label={t("trix_editor.modal_copy.embed_link")}>
      <EmbedForm
        {...props}
        onClose={onClose}
        embedPlaceholder={embedPlaceholder}
        embedDesc={embedDesc}
        helperText={helperText}
        embedBtn={embedBtn}
      />
    </div>
    <div label={<UnsplashTab />}>
      <UnsplashForm onClose={onClose} {...props} />
    </div>
  </Tabs>
);
