import PropTypes from "prop-types";
import { SeeMoreLess } from "@circle-react-shared/uikit/SeeMoreLess";
import { FIELD_TYPES, NUMERIC_FORMATS } from "../../constants";

const getTextDisplay = ({
  community_member_profile_field: communityMemberProfileField,
}) => {
  const { text } = communityMemberProfileField ?? {};
  if (text?.trim() === "") {
    return null;
  }

  return text;
};

const NumberDisplay = ({
  community_member_profile_field: communityMemberProfileField,
  number_options: { format },
}) => {
  const { text } = communityMemberProfileField ?? {};
  if (!text?.trim()) {
    return null;
  }

  if (format === NUMERIC_FORMATS.PERCENT) {
    return `${text}%`;
  }
  return text;
};

const LinkDisplay = ({
  community_member_profile_field: communityMemberProfileField,
}) => {
  const { text } = communityMemberProfileField ?? {};

  if (!text || text?.trim === "") {
    return null;
  }
  return (
    <a href={text} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  );
};

const TextAreaDisplay = ({
  community_member_profile_field: communityMemberProfileField,
}) => {
  const { textarea } = communityMemberProfileField ?? {};
  const text = getTextDisplay({
    community_member_profile_field: { text: textarea },
  });

  if (!text) {
    return null;
  }

  return (
    <SeeMoreLess lines={8} seeMoreClassName="text-sm">
      {text}
    </SeeMoreLess>
  );
};

const ChoicesDisplay = ({
  community_member_profile_field: communityMemberProfileField,
}) => {
  if (communityMemberProfileField === null) {
    return null;
  }

  const { community_member_choices: choices = [] } =
    communityMemberProfileField;
  if (!choices.length) {
    return null;
  }
  return choices
    .map(({ profile_field_choice: choice }) => choice.value)
    .join(", ");
};

export const FieldTypeDisplayInformation = {
  [FIELD_TYPES.TEXT]: getTextDisplay,
  [FIELD_TYPES.LINK]: LinkDisplay,
  [FIELD_TYPES.TEXTAREA]: TextAreaDisplay,
  [FIELD_TYPES.NUMBER]: NumberDisplay,
  [FIELD_TYPES.SELECT]: ChoicesDisplay,
  [FIELD_TYPES.CHECKBOX]: ChoicesDisplay,
};

ChoicesDisplay.propTypes = {
  community_member_profile_field: PropTypes.shape({
    community_member_choices: PropTypes.arrayOf(
      PropTypes.shape({
        profile_field_choice: PropTypes.shape({
          value: PropTypes.string,
        }),
      }),
    ),
  }),
};

TextAreaDisplay.propTypes = {
  community_member_profile_field: PropTypes.shape({
    textarea: PropTypes.string,
  }),
};

getTextDisplay.propTypes = {
  community_member_profile_field: PropTypes.shape({
    text: PropTypes.string,
  }),
};

NumberDisplay.propTypes = {
  community_member_profile_field: PropTypes.shape({
    text: PropTypes.string,
  }),
  number_options: PropTypes.shape({
    format: PropTypes.string,
  }),
};

LinkDisplay.propTypes = {
  community_member_profile_field: PropTypes.shape({
    text: PropTypes.string,
  }),
};
