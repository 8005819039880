import PropTypes from "prop-types";
import { t } from "i18n-js";
import { useArchivedFieldsMutation } from "@circle-react/components/SettingsApp/ProfileFields/hooks/useArchivedFieldsMutation";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";

export const ArchiveConfirmation = ({ fieldRow, isVisible, onClose }) => {
  const {
    archiveFieldMutations: { mutate: onArchiveField },
  } = useArchivedFieldsMutation();
  const { id, label } = fieldRow;

  const handleArchiveField = () => {
    onArchiveField(
      { id },
      {
        onSuccess: () => {
          onClose();
        },
      },
    );
  };

  return (
    <ConfirmationModal
      isOpen={isVisible}
      onClose={onClose}
      title={t("profile_fields.archive_confirm.title", {
        fieldLabel: label,
      })}
      onConfirm={handleArchiveField}
      confirmVariant="danger"
      cancelText={t("cancel")}
    >
      <p>{t("profile_fields.archive_confirm.description")}</p>
    </ConfirmationModal>
  );
};

ArchiveConfirmation.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  fieldRow: PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
  }).isRequired,
};
