import PropTypes from "prop-types";
import classNames from "classnames";

export const BigSpinner = ({ animate = true }) => (
  <svg
    width="104"
    height="104"
    viewBox="0 0 104 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames({ "animate-spin": animate })}
  >
    <circle
      cx="52"
      cy="52"
      r="48"
      className="text-stroke-background stroke-current"
      strokeWidth="5"
    />
    <path
      d="M52 100C58.3034 100 64.5452 98.7584 70.3688 96.3462C76.1924 93.934 81.4839 90.3983 85.9411 85.9411C90.3983 81.4839 93.934 76.1924 96.3462 70.3688C98.7584 64.5452 100 58.3034 100 52"
      className="text-stroke-foreground stroke-current"
      strokeWidth="5"
      strokeLinecap="round"
    />
  </svg>
);

BigSpinner.propTypes = {
  animate: PropTypes.bool,
};
