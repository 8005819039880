import PropTypes from "prop-types";
import classNames from "classnames";
import { Block } from "./Block";

export const SquareWithBorder = ({ className, ...rest }) => (
  <Block
    className={classNames(
      "border-tertiary h-5 w-5 rounded border-2",
      className,
    )}
    {...rest}
  />
);

SquareWithBorder.propTypes = {
  className: PropTypes.string,
};
