import type { ReactNode } from "react";
import { BigCheckmark } from "@circle-react-shared/BigCheckmark";
import { BigCross } from "@circle-react-shared/BigCross";
import { BigSpinner } from "@circle-react-shared/BigSpinner";
import { Typography } from "@circle-react-uikit/Typography";

interface BigLoaderProps {
  animate?: boolean;
  title?: ReactNode;
  subtitle?: ReactNode;
  variant?: "loading" | "success" | "error";
}

export const BigLoader = ({
  animate = true,
  title,
  subtitle,
  variant = "loading",
}: BigLoaderProps) => (
  <div className="bg-primary flex h-full w-full flex-col items-center justify-center">
    <div className="flex h-full w-full max-w-xl flex-col items-center justify-center gap-4">
      <div className="pb-5">
        {variant === "loading" && <BigSpinner animate={animate} />}
        {variant === "success" && <BigCheckmark />}
        {variant === "error" && <BigCross />}
      </div>
      {title && (
        <div className="text-center">
          <Typography.TitleMd>{title}</Typography.TitleMd>
        </div>
      )}
      {subtitle && (
        <div className="text-center">
          <Typography.LabelMd color="text-default">
            {subtitle}
          </Typography.LabelMd>
        </div>
      )}
    </div>
  </div>
);
