export const BigCross = () => (
  <svg
    width="104"
    height="104"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="32" cy="32" r="32" fill="#E02F2F" />
    <path
      d="M25 39L39 25M25 25L39 39"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
