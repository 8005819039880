import PropTypes from "prop-types";
import classNames from "classnames";
import { Block } from "./Block";

export const LargeRectangle = ({ className, ...rest }) => (
  <Block className={classNames("h-4 rounded", className)} {...rest} />
);

LargeRectangle.propTypes = {
  className: PropTypes.string,
};
