import PropTypes from "prop-types";
import { t } from "i18n-js";
import { useDeleteField } from "@circle-react/components/SettingsApp/ProfileFields/hooks/useDeleteField";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";

export const DeleteConfirmation = ({ fieldRow, isVisible, onClose }) => {
  const { mutate: onDeleteField } = useDeleteField();
  const { id, label } = fieldRow;

  const handleDeleteField = () => {
    onDeleteField(
      { id },
      {
        onSuccess: onClose,
      },
    );
  };

  return (
    <ConfirmationModal
      isOpen={isVisible}
      onClose={onClose}
      title={t("profile_fields.delete_confirm.title", {
        fieldLabel: label,
      })}
      onConfirm={handleDeleteField}
      confirmVariant="danger"
      cancelText={t("cancel")}
    >
      <p>{t("profile_fields.delete_confirm.description")}</p>
    </ConfirmationModal>
  );
};

DeleteConfirmation.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  fieldRow: PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
  }).isRequired,
};
