import type { MouseEventHandler, ReactNode } from "react";
import classNames from "classnames";
import invariant from "tiny-invariant";
import { Icon } from "@circle-react-shared/Icon";
import { AddonFilterChip } from "./AddonFilterChip";
import type { SelectedText } from "./types";

interface FilterChipProps {
  children: ReactNode;
  isFocused?: boolean;
  /** If there is a selected value pass in to the component */
  selectedText?: SelectedText;
  /** If you need to keep selected value the same but render a different label */
  renderSelectedText?: ({
    selectedText,
  }: {
    selectedText: SelectedText;
  }) => ReactNode;
  /** callback for when clear is clicked */
  onClear?: () => void;
  /** Clear button: Shown if there is selected text, unless disabled. */
  shouldShowClearButton?: boolean;
}

const defaultRenderSelectedText = ({
  selectedText,
}: {
  selectedText: SelectedText;
}) => {
  const getTitle = () => {
    if (Array.isArray(selectedText)) {
      return selectedText.join(", ");
    }
    invariant(
      typeof selectedText !== "object",
      "use renderSelectedText when passing in object selected values",
    );
    return selectedText ?? undefined;
  };
  return (
    <p className="max-w-[32ch] truncate" title={getTitle()}>
      {selectedText}
    </p>
  );
};

export const FilterChip = ({
  children,
  isFocused = false,
  selectedText = null,
  renderSelectedText = defaultRenderSelectedText,
  onClear,
  shouldShowClearButton = true,
}: FilterChipProps) => {
  const handleClear: MouseEventHandler<HTMLButtonElement> = event => {
    event.stopPropagation();
    onClear?.();
  };

  return (
    <button
      type="button"
      className={classNames(
        "hover:border-secondary focus-visible:border-secondary disabled:hover:border-primary flex h-6 items-center rounded-3xl border px-2 text-xs transition-colors disabled:cursor-not-allowed disabled:opacity-50",
        isFocused
          ? "text-dark border-secondary"
          : "text-default border-primary",
        { "text-dark": Boolean(selectedText) },
      )}
    >
      <div className="divide-selector-inactive flex divide-x-[1.25px]">
        <div className="flex items-center gap-x-1 pr-2">
          <AddonFilterChip
            selectedText={selectedText}
            shouldShowClearButton={shouldShowClearButton}
            onClear={handleClear}
          />
          {children}
        </div>
        {selectedText && (
          <div className="text-v2-info flex items-center gap-x-1 pl-2">
            {renderSelectedText({ selectedText })}
            <Icon
              type="16-chevron-down"
              size={12}
              className="text-default mb-1"
            />
          </div>
        )}
      </div>
    </button>
  );
};
