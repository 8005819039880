import { t } from "i18n-js";
import { Tabs } from "@circle-react-uikit/Tabs";
import { AttachFileForm } from "../AttachFileForm";
import { EmbedForm } from "../EmbedForm";

export const FileSelector = (
  { embedPlaceholder, dropTitle, helperText, embedDesc, embedBtn, onClose },
  ...props
) => (
  <Tabs defaultValue="Upload">
    <div label={t("upload")}>
      <div className="pt-3">
        <AttachFileForm
          onClose={onClose}
          dropTitle={dropTitle}
          helperText={helperText}
          {...props}
        />
      </div>
    </div>
    <div label={t("trix_editor.modal_copy.embed_link")}>
      <EmbedForm
        {...props}
        onClose={onClose}
        embedPlaceholder={embedPlaceholder}
        helperText={helperText}
        embedDesc={embedDesc}
        embedBtn={embedBtn}
      />
    </div>
  </Tabs>
);
