import { EmbedSelector } from "./EmbedSelector";
import { FileSelector } from "./FileSelector";
import { ImageSelector } from "./ImageSelector";
import { modalCopy } from "./ModalCopy";
import { ModalWrapper } from "./ModalWrapper";

export const Selector = ({ attribute, subtype, onClose }) => {
  // Subtype related copy
  const copy = modalCopy(subtype);
  const embedPlaceholder = copy.embedPlaceholder;
  const embedDesc = copy.embedDesc || "";
  const dropTitle = copy.dropTitle;
  const helperText = copy.helperText;
  const embedBtn = copy.embedBtn;
  const itemName = copy.itemName;

  // Attributes
  const img = "attachment_image";
  const video = "attachment_video";
  const file = "attachment_file";
  const pdf = "attachment_pdf";
  const audio = "attachment_audio";
  const embed = "embed";

  // Render modal based on selected attribute
  if (attribute === img) {
    return (
      <ModalWrapper onClose={onClose} title={itemName}>
        <ImageSelector
          onClose={onClose}
          embedPlaceholder={embedPlaceholder}
          dropTitle={dropTitle}
          helperText={helperText}
          embedBtn={embedBtn}
          embedDesc={embedDesc}
        />
      </ModalWrapper>
    );
  }
  if (
    attribute === file ||
    attribute === pdf ||
    attribute === audio ||
    attribute === video
  ) {
    return (
      <ModalWrapper onClose={onClose} title={itemName}>
        <FileSelector
          onClose={onClose}
          embedPlaceholder={embedPlaceholder}
          dropTitle={dropTitle}
          helperText={helperText}
          embedDesc={embedDesc}
          embedBtn={embedBtn}
        />
      </ModalWrapper>
    );
  }
  if (attribute === embed) {
    return (
      <ModalWrapper onClose={onClose} title={itemName}>
        <EmbedSelector
          onClose={onClose}
          embedPlaceholder={embedPlaceholder}
          dropTitle={dropTitle}
          helperText={helperText}
          embedBtn={embedBtn}
        />
      </ModalWrapper>
    );
  }
  return null;
};
