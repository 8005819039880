import { embedsPath } from "../../../../helpers/urlHelpers";

const createEmbed = ({ content }) =>
  fetch(embedsPath(), {
    body: JSON.stringify({
      embed: {
        content,
      },
    }),
    method: "POST",
    dataType: "script",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

export const embedContent = async ({
  content,
  trixApiWrapper,
  skipRecordUndoEntry = false,
  skipLineBreak = false,
}) => {
  if (!skipRecordUndoEntry) {
    trixApiWrapper.recordUndoEntry();
  }

  const attachment = trixApiWrapper.insertAttachment({
    // eslint-disable-next-line no-restricted-syntax -- Not a translatable string
    content: '<div class="trix-preloader" />',
    contentType: "embed",
  });

  if (!skipLineBreak) {
    trixApiWrapper.insertLineBreak();
  }

  const response = await createEmbed({
    content,
  });

  if (response.ok) {
    const { content, sgid } = await response.json();
    attachment.setAttributes({
      content,
      contentType: "embed",
      sgid,
    });
  } else {
    const selectedRange = trixApiWrapper.getSelectedRange();
    const range = trixApiWrapper.getRangeOfAttachment(attachment);
    trixApiWrapper.setSelectedRange(range);
    trixApiWrapper.insertString(content);
    trixApiWrapper.setSelectedRange([range[0], range[0] + content.length]);
    trixApiWrapper.activateAttribute("href", content);
    trixApiWrapper.setSelectedRange(selectedRange.map(i => i + content.length));
  }
};
