import { useQuery } from "react-query";
import { accountSubscriptionApi } from "@/react/api/accountSubscriptionApi";
import type { ApiError } from "@circle-react/config/CustomErrors";
import type { CanDowngradeResponse } from "../types";

export const useCanDowngrade = (planId: number) => {
  const { data, error, isLoading } = useQuery<CanDowngradeResponse, ApiError>(
    ["canDowngrade", planId],
    () => accountSubscriptionApi.canDowngrade(planId),
  );

  return {
    data,
    isLoading,
    error,
  };
};
