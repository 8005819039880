import type { ReactNode } from "react";
import classNames from "classnames";
import { t } from "i18n-js";
import { Button } from "@circle-react-uikit/Button";
import { Modal } from "@circle-react-uikit/ModalV2";

type AnyFunction = (...args: any) => any;

export interface ModalWrapperProps {
  onClose: AnyFunction;
  title?: string;
  children: ReactNode;
  footerActions: {
    onSaveChanges: AnyFunction;
    onCancel: AnyFunction;
  };
  shouldShowActionsFooter?: boolean;
  isOpen?: boolean;
}

export const ModalWrapper = ({
  onClose,
  title,
  children,
  footerActions,
  shouldShowActionsFooter = false,
  isOpen = true,
}: ModalWrapperProps) => (
  <Modal
    isOpen={isOpen}
    onClose={onClose}
    className="modal__box trix-modal__upload editor-modal fullpage-modal__attachment"
  >
    <Modal.Overlay />
    <Modal.Content size="lg">
      <Modal.Header
        className={classNames({
          "justify-between": !!title,
          "!justify-end": !title,
        })}
      >
        {title && <Modal.Title size="sm">{title}</Modal.Title>}
        <Modal.HeaderActions>
          <Modal.CloseButton onClick={onClose} />
        </Modal.HeaderActions>
      </Modal.Header>
      <Modal.Body className="!pt-0 md:pb-6">{children}</Modal.Body>
      {shouldShowActionsFooter && (
        <Modal.Footer className="flex justify-end space-x-4">
          <Button
            type="button"
            variant="secondary"
            onClick={footerActions.onCancel}
          >
            {t("cancel")}
          </Button>
          <Button
            type="submit"
            variant="circle"
            onClick={footerActions.onSaveChanges}
          >
            {t("save_changes")}
          </Button>
        </Modal.Footer>
      )}
    </Modal.Content>
  </Modal>
);
