import { t } from "i18n-js";
import { ASPECT_RATIO } from "@circle-react/components/constants";

export const aspectRatioLabels = {
  [ASPECT_RATIO.THUMBNAIL]: t("image_upload.recommended_size_thumbnail"),
  [ASPECT_RATIO.OPEN_GRAPH]: t("image_upload.recommended_size_opengraph"),
  [ASPECT_RATIO.DEFAULT]: t("image_upload.recommended_size_default"),
  [ASPECT_RATIO.SPACE_COVER]: t("image_upload.recommended_size_v2_cover"),
  [ASPECT_RATIO.COVER_V3]: t("image_upload.recommended_size_v3_cover"),
} as const;
