import { useMemo } from "react";
import { t } from "i18n-js";
import { Form } from "@circle-react-uikit/Form";
import type { Option } from "@circle-react-uikit/Form/FormSelectNative";

export interface Choice {
  id: number;
  value: string;
}

export interface CustomSelectProps {
  name: string;
  choices: Choice[];
  required?: boolean;
  disabled?: boolean;
}

export const CustomSelect = ({
  name,
  choices,
  required,
  disabled,
}: CustomSelectProps) => {
  const options = useMemo<Option[]>(
    () =>
      [
        {
          label: t("profile_fields.form.default_select_option"),
          value: "",
        },
      ].concat(
        choices.map(({ id, value }) => ({
          label: value,
          value: id.toString(),
        })),
      ),
    [choices],
  );

  return (
    <Form.SelectNative
      name={name}
      options={options}
      required={required}
      disabled={disabled}
    />
  );
};
