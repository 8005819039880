/* eslint-disable max-lines
-- Disabled to set CI to fail on this issue on new files, PR #6757 */
import { createContext, useContext, useEffect, useState } from "react";
import { debounce } from "lodash";
import { isMobileDevice } from "../../../helpers/browserHelpers";
import { useDetectEscapeKeyPress } from "../../../hooks/useDetectEscapeKeyPress";
import { useMdScreenMediaQuery } from "../../../hooks/useMediaQuery";
import { SHORTCUT_PATTERN_ACTIONS } from "./InlineToolbar/ACTIONS";
import { TrixApiWrapper } from "./TrixApiWrapper";

const ATTACHMENT_REPRESENTATION_CHAR = String.fromCharCode(65532);
const inlineToolbarMode = "inline-toolbar";

export const TrixEditorModeContext = createContext();
TrixEditorModeContext.displayName = "TrixEditorModeContext";
export const useEditorMode = () => useContext(TrixEditorModeContext);

export const useManageEditorMode = editorElement => {
  const [mode, setMode] = useState(null);
  const [subMode, setSubMode] = useState(null);
  const [subType, setSubType] = useState(null);
  const [startPosition, setStartPosition] = useState(null);
  const [startRect, setStartRect] = useState({});
  const [endPosition, setEndPosition] = useState(null);
  const [lastKnownPosition, setLastKnownPosition] = useState(null);
  const [mousedown, setMousedown] = useState(false);
  const mdScreen = useMdScreenMediaQuery();

  const hasWhiteSpace = s => /\s/g.test(s);

  const resetEditorMode = () => {
    setMode(null);
    setSubMode(null);
    setSubType(null);
    setStartPosition(null);
    setEndPosition(null);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (mode && startPosition !== null && editorElement) {
        setStartRect(computeStartPositionRect());
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [startPosition, setStartRect, mode, editorElement]);

  useEffect(() => {
    if (startPosition !== null) {
      setStartRect(computeStartPositionRect());
    } else {
      setStartRect({});
    }
  }, [startPosition, editorElement]);

  // eslint-disable-next-line sonarjs/cognitive-complexity -- Disabled to set CI to fail on this issue on new files, PR #6718
  useEffect(() => {
    if (!editorElement) {
      return;
    }
    const { editor } = editorElement;
    if (!editor) {
      return;
    }

    const onTrixChange = event => {
      if (!editor) return;

      const documentText = editor.getDocument().toString();
      const cp = editor.getPosition();
      const trixApiWrapper = new TrixApiWrapper(editor);
      const matchPatternAtStartOfNewLine = stringPattern => {
        const length = stringPattern.length;
        const prefixCharPosition = cp - (length + 1);
        const isPrefixByNothing = cp === length; // start of content
        const isPrefixByEnter = documentText[prefixCharPosition] === "\n";
        const isPrefixedByAttachment =
          documentText[prefixCharPosition] === ATTACHMENT_REPRESENTATION_CHAR;
        const startsOnANewLine =
          isPrefixByNothing || isPrefixByEnter || isPrefixedByAttachment;
        const cpPrefixByStringPattern =
          stringPattern[length - 1] === event.key &&
          documentText.substr(cp - length, length) === stringPattern;
        return cpPrefixByStringPattern && startsOnANewLine;
      };

      let action;
      SHORTCUT_PATTERN_ACTIONS.forEach(act => {
        if (matchPatternAtStartOfNewLine(act.shortcutPatten)) {
          action = act;
        }
      });

      if (action) {
        editor.recordUndoEntry(
          `Applying shortcurt pattern ${action.attribute}`,
        );
        editor.setSelectedRange([cp - action.shortcutPatten.length, cp]);
        editor.deleteInDirection("backward");
        // eslint-disable-next-line sonarjs/no-small-switch -- Disabled to set CI to fail on this issue on new files, PR #6718
        switch (action.attribute) {
          case "divider":
            trixApiWrapper.insertHr();
            break;
          default:
            trixApiWrapper.activateAttribute(action.attribute);
        }
      } else if (
        mode === "inline-emoji-selector" &&
        (documentText[startPosition] !== ":" ||
          hasWhiteSpace(documentText.substring(startPosition + 1, cp)))
      ) {
        resetEditorMode();
      } else if (event.key === ":" && mdScreen) {
        setMode("inline-emoji-selector");
        setStartPosition(cp - 1);
      } else if (matchPatternAtStartOfNewLine("/") && mdScreen) {
        setModeInlineBlockSelector();
      } else if (
        mode === "inline-block-selector" &&
        subMode === "withPrefix" &&
        documentText[startPosition] !== "/"
      ) {
        resetEditorMode();
      }
    };

    editorElement.addEventListener("keyup", onTrixChange);
    return () => {
      if (editorElement) {
        editorElement.removeEventListener("keyup", onTrixChange);
      }
    };
  }, [mode, setMode, editorElement, startPosition, setStartPosition]);

  const setModeInlineBlockSelector = ({ withPrefix = true } = {}) => {
    const { editor } = editorElement;
    if (!editor) return;
    const cp = editor.getPosition();
    setMode("inline-block-selector");
    if (withPrefix) setSubMode("withPrefix");
    let sp = cp;
    if (withPrefix) {
      sp = cp - 1;
    }
    setStartPosition(sp);
    setStartRect(trixApiWrapper.getClientRectAtPosition(sp));
  };

  const mayBeShowInlineToolbar = () => {
    if (isMobileDevice()) {
      return;
    }
    const documentText = editorElement.editor.getDocument().toString();
    const [start, end] = editorElement.editor.getSelectedRange();
    if (start !== end) {
      if (
        documentText
          .substr(start, end - start)
          .includes(ATTACHMENT_REPRESENTATION_CHAR)
      ) {
        return;
      }
      setMode(inlineToolbarMode);
      setStartPosition(start);
      setEndPosition(end);
    }
  };

  const mayBeShowToolTips = () => {
    if (isMobileDevice()) {
      return;
    }
    if (trixApiWrapper.attributeIsActive("href")) {
      setMode("hyperlink-tooltip");
      setStartPosition(trixApiWrapper.getPosition());
    } else if (mode === "hyperlink-tooltip") {
      resetEditorMode();
    }
  };

  useEffect(() => {
    if (!editorElement) return;
    if (isMobileDevice()) {
      return;
    }
    const setMousedownTrue = () => setMousedown(true);
    let setMousedownFalse = () => {
      setMousedown(false);
      const [start, end] = editorElement.editor.getSelectedRange();
      if (mode !== inlineToolbarMode && start !== end) {
        setTimeout(mayBeShowInlineToolbar, 100);
      }
    };
    setMousedownFalse = debounce(setMousedownFalse);

    document.addEventListener("mouseup", setMousedownFalse);
    document.addEventListener("mousedown", setMousedownTrue);

    return () => {
      document.removeEventListener("mouseup", setMousedownFalse);
      document.removeEventListener("mousedown", setMousedownTrue);
    };
  }, [editorElement, setMousedown, mode, mayBeShowInlineToolbar]);

  useDetectEscapeKeyPress(resetEditorMode);

  const handleMetaKeyShortcuts = event => {
    if (isMobileDevice()) {
      return;
    }
    if (event.key === "k" && showInlineToolbar()) {
      event.preventDefault();
      toggleOnInlineLinkForm();
    }
  };

  useEffect(() => {
    if (!editorElement) {
      return;
    }
    const handleKeyPress = event => {
      if (event.metaKey) {
        handleMetaKeyShortcuts(event);
      }
    };
    editorElement.addEventListener("keydown", handleKeyPress);
    return () => {
      editorElement.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleMetaKeyShortcuts, editorElement]);

  useEffect(() => {
    if (!editorElement) return;
    const onTrixSelection = () => {
      setLastKnownPosition(editorElement.editor.getPosition());
      const [start, end] = editorElement.editor.getSelectedRange();
      if (start === end) {
        if (mode === inlineToolbarMode) {
          resetEditorMode();
        }
        mayBeShowToolTips();
      } else {
        if (mode === "hyperlink-tooltip") {
          resetEditorMode();
        }
        if (!mousedown) {
          mayBeShowInlineToolbar();
        }
      }
    };

    editorElement.addEventListener("trix-selection-change", onTrixSelection);
    // editorElement.addEventListener("mouseup", mayBeShowInlineToolbar);
    return () => {
      editorElement.removeEventListener(
        "trix-selection-change",
        onTrixSelection,
      );
      // editorElement.removeEventListener("mouseup", mayBeShowInlineToolbar);
    };
  }, [
    mode,
    setMode,
    editorElement,
    startPosition,
    setStartPosition,
    mousedown,
    setLastKnownPosition,
    lastKnownPosition,
    mayBeShowInlineToolbar,
  ]);

  const trixApiWrapper = new TrixApiWrapper(editorElement?.editor);
  const computeStartPositionRect = () => {
    if (!editorElement) {
      return;
    }
    let rect = trixApiWrapper.getClientRectAtPosition(startPosition);
    if (rect) return rect;
    rect = editorElement.getBoundingClientRect().toJSON();
    rect.right = rect.left;
    rect.bottom = rect.top;
    return rect;
  };

  const validStartPosition = () => ![null, undefined].includes(startPosition);
  const validEndPosition = () => ![null, undefined].includes(endPosition);
  const startPositionRect = () => startRect;
  // trixApiWrapper.getClientRectAtPosition(startPosition);
  const endPositionRect = () =>
    trixApiWrapper.getClientRectAtPosition(endPosition);
  const showInlineEmojiSelector = () =>
    mode === "inline-emoji-selector" && validStartPosition();
  const triggerInlineEmojiSelector = () => {
    trixApiWrapper.insertString(":");
    setMode("inline-emoji-selector");
    setStartPosition(trixApiWrapper.getPosition() - 1);
  };
  const showInlineToolbar = () =>
    mode === inlineToolbarMode && validStartPosition() && validEndPosition();

  const toggleOnInlineLinkForm = () => {
    setMode(inlineToolbarMode);
    setSubMode("href");
  };

  const toggleOffInlineLinkForm = () => {
    setSubMode(null);
  };
  const showInlineLinkForm = () =>
    mode === inlineToolbarMode && subMode === "href";

  const showHyperlinkTooltip = () =>
    mode === "hyperlink-tooltip" && validStartPosition();

  const showInlineBlockSelector = () =>
    mode === "inline-block-selector" && validStartPosition();

  const setModeModalBlockSelector = () => {
    setStartPosition(trixApiWrapper.getPosition());
    setMode("modal-block-selector");
  };
  const showModalBlockSelector = () => mode === "modal-block-selector";

  const showInlineModalSelector = () =>
    mode === "inline-modal-selector" && subMode;

  const setModeInlineModalSelector = subMode => {
    setMode("inline-modal-selector");
    setSubMode(subMode);
  };
  const setSubTypeInlineModalSelector = subType => {
    setMode("inline-modal-selector");
    setSubType(subType);
  };
  const showEmojiPicker = () => mode === "toolbar-emoji-picker";
  const setModeEmojiPicker = () => {
    setMode("toolbar-emoji-picker");
  };
  const showGiphyPicker = () => mode === "toolbar-giphy-picker";
  const setModeGiphyPicker = () => {
    setMode("toolbar-giphy-picker");
  };
  const restoreToLastKnownPosition = () => {
    const newLocation = lastKnownPosition || 0;
    return trixApiWrapper.setSelectedRange([newLocation, newLocation]);
  };

  return {
    mode,
    subMode,
    subType,
    startPosition,
    endPosition,
    resetEditorMode,
    showInlineEmojiSelector,
    triggerInlineEmojiSelector,
    showInlineToolbar,
    showHyperlinkTooltip,
    showInlineBlockSelector,
    showInlineModalSelector,
    startPositionRect,
    endPositionRect,
    setModeInlineModalSelector,
    setSubTypeInlineModalSelector,
    setModeInlineBlockSelector,
    showEmojiPicker,
    setModeEmojiPicker,
    showGiphyPicker,
    setModeGiphyPicker,
    restoreToLastKnownPosition,
    toggleOnInlineLinkForm,
    toggleOffInlineLinkForm,
    showInlineLinkForm,
    setModeModalBlockSelector,
    showModalBlockSelector,
  };
};
