import { useState } from "react";
import type { ReactElement } from "react";
import classNames from "classnames";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";

export interface LineItemProps {
  title: string;
  description?: string;
  amount: string;
  color?: string;
  semibold?: boolean;
  amountWeight?: string;
  isExpandable?: boolean;
  expandedContent?: ReactElement | null;
}

export const LineItem = ({
  title,
  description,
  amount,
  color = "default",
  semibold = false,
  amountWeight = "normal",
  isExpandable = false,
  expandedContent = null,
}: LineItemProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(prevExpanded => !prevExpanded);
  };

  return (
    <div className="mb-4 last:mb-0">
      <button
        type="button"
        className={classNames("flex w-full items-start justify-between", {
          "cursor-default": !isExpandable,
        })}
        onClick={toggleExpanded}
      >
        <div className="text-left">
          <Typography.LabelMd weight={semibold ? "semibold" : "normal"}>
            {title}
          </Typography.LabelMd>
          {isExpandable && (
            <Icon
              size={16}
              type="caret-down-solid"
              className={`text-dark ml-2 ${isExpanded ? "" : "-rotate-90"}`}
            />
          )}
          <Typography.BodyXs color="text-light">
            {description}
          </Typography.BodyXs>
        </div>
        <div className="whitespace-nowrap">
          <Typography.LabelMd weight={amountWeight} color={color}>
            {amount}
          </Typography.LabelMd>
        </div>
      </button>
      {isExpanded && <div className="mt-2">{expandedContent}</div>}
    </div>
  );
};
