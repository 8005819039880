import PropTypes from "prop-types";

const sizeMap = {
  sm: 24,
  md: 48,
  lg: 88,
};

export const EmptyAvatar = ({ size = "sm", ...rest }) => {
  const finalSize = sizeMap[size] || sizeMap.sm;

  return (
    <svg
      width={finalSize}
      height={finalSize}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect
        width="24"
        height="24"
        rx="4"
        fill="var(--color-background-avatar-secondary)"
      />
      <ellipse
        cx="12"
        cy="9"
        rx="5.25"
        ry="6"
        fill="var(--color-background-avatar-primary)"
      />
      <path
        d="M22.5 23.9999H1.5C2.68942 19.8354 7.06933 17.0052 12 17.0052C16.9307 17.0052 21.3106 19.8354 22.5 23.9999Z"
        fill="var(--color-background-avatar-primary)"
      />
    </svg>
  );
};

EmptyAvatar.propTypes = {
  size: PropTypes.oneOf(["sm", "md", "lg"]),
};
