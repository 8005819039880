import { useEffect, useState } from "react";
import { UnsplashTabContent } from "../../Unsplash/UnsplashTabContent";
import { unsplashCreateApi } from "../../Unsplash/unsplashCreateApi";
import { useEditorApi } from "../TrixEditorContext";

export const UnsplashForm = ({ onClose }) => {
  const { insertFile } = useEditorApi();
  const [unsplashResults, setUnsplashResults] = useState([]);

  const addUnsplash = item => {
    trackUnsplashApiDownload(item);
    fetch(item.urls.regular)
      .then(res => res.blob()) // Gets the response and returns it as a blob
      .then(blob => {
        const file = new File([blob], item.id, {
          type: "image/jpeg",
        });
        insertFile(file);
      })
      .catch(error => {
        console.error(error);
      });
    onClose();
  };

  const trackUnsplashApiDownload = item => {
    unsplashCreateApi.photos.trackDownload({
      downloadLocation: item.links.download_location,
    });
  };

  async function searchUnsplash(string) {
    const query = string.trim();
    setUnsplash(query);
  }

  async function setUnsplash(q) {
    const search = await unsplashCreateApi.search.getPhotos({
      query: q,
      page: 1,
      perPage: 30,
    });

    const results = search.response.results;
    if (q.length >= 1 && results.length >= 1) {
      setUnsplashResults(results);
    }
  }

  useEffect(() => {
    // Set initial unsplash results state
    setUnsplash("wallpapers");
  }, []);

  return (
    <UnsplashTabContent
      unsplashResults={unsplashResults}
      searchUnsplash={searchUnsplash}
      addUnsplash={addUnsplash}
    />
  );
};
