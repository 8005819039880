import { t } from "i18n-js";
import * as yup from "yup";
import { findDecimalPlaces } from "@circle-react/helpers/number";
import { prependHttps } from "@circle-react-shared/uikit/Form/LinkInput/prependHttps";
import {
  FIELD_TYPES,
  FIELD_TYPES_TO_BACKEND_VALUE_PARAM,
  NUMERIC_FORMATS,
  choiceAttributeFieldTypes,
} from "../constants";

const STRING_MAX_LIMIT = 255;
const BIO_MAX_LIMIT = 4096;
const i18nRoot = "profile_fields.form";

yup.addMethod(yup.array, "tuple", function (schema) {
  if (!this.isType(schema)) yup.ValidationError();
  return yup
    .object({
      tuple: yup.array().min(schema.length).max(schema.length),
      ...Object.fromEntries(Object.entries(schema)),
    })
    .transform((value, originalValue) => {
      if (!this.isType(originalValue)) yup.ValidationError();
      return {
        tuple: originalValue,
        ...Object.fromEntries(Object.entries(originalValue)),
      };
    });
});

const typeTranslations = {
  [FIELD_TYPES.TEXT]: ({ label }) =>
    yup
      .string()
      .trim()
      .max(
        STRING_MAX_LIMIT,
        t([i18nRoot, "is_max_length"], {
          fieldName: label,
        }),
      ),
  [FIELD_TYPES.NUMBER]: ({ label, number_options: numberOptions }) => {
    const { decimal: allowedDecimalPlaces, format } = numberOptions;
    return yup
      .string()
      .trim()
      .test((value, ctx) => {
        if (value === "") return true;
        const number = Number(+value);
        if (Number.isNaN(number)) {
          return ctx.createError({
            message: t([i18nRoot, "is_number"], {
              fieldName: label,
            }),
          });
        }
        return true;
      })
      .test((value, ctx) => {
        if (value === "") return true;
        const number = Number(+value);
        if (Number.isNaN(number)) return true;
        if (format === NUMERIC_FORMATS.UNFORMATTED) return true;

        const decimalPlaces = findDecimalPlaces(number);
        if (decimalPlaces > allowedDecimalPlaces) {
          return ctx.createError({
            message: t([i18nRoot, "is_number_decimal_length"], {
              count: allowedDecimalPlaces,
              fieldName: label,
            }),
          });
        }
        return true;
      });
  },
  [FIELD_TYPES.LINK]: ({ label }) =>
    yup
      .string()
      .trim()
      .transform((value, originalValue) => prependHttps(originalValue))
      .url(
        t([i18nRoot, "is_valid_url"], {
          fieldName: label,
        }),
      ),
  [FIELD_TYPES.TEXTAREA]: ({ label, key }) => {
    /* Since Bio has to be synced back to the original field
        and the original field is max 4096 characters
        adding a stop gap validation here
        @todo remove when syncing is removed
      */
    if (key === "bio") {
      return yup
        .string()
        .trim()
        .max(
          BIO_MAX_LIMIT,
          t([i18nRoot, "bio_is_max_length"], {
            fieldName: label,
          }),
        );
    }
    return yup
      .string()
      .trim()
      .max(
        65535,
        t([i18nRoot, "is_textarea_max_length"], {
          fieldName: label,
        }),
      );
  },
  [FIELD_TYPES.SELECT]: () => yup.array().ensure().nullable(),
  [FIELD_TYPES.CHECKBOX]: () => yup.array().nullable(),
};

const addRequiredStatus = (
  type,
  { required, label, field_type: fieldType },
) => {
  if (choiceAttributeFieldTypes.includes(fieldType) && required) {
    return type
      .compact(v => !v)
      .min(required ? 1 : 0, t([i18nRoot, "is_atleast_one_selected"]))
      .required(
        t([i18nRoot, "is_required"], {
          fieldName: label,
        }),
      );
  }
  if (required) {
    return type.required(
      t([i18nRoot, "is_required"], {
        fieldName: label,
      }),
    );
  }
  return type;
};

export const getFieldTypeValidator = (field, isAdmin) => {
  const { field_type: fieldType } = field;
  const fieldTypeValidator = typeTranslations[fieldType](field);
  if (isAdmin) {
    return fieldTypeValidator;
  }
  return addRequiredStatus(fieldTypeValidator, field);
};

/**
 * Get validation schema for profile fields
 * @param {Object} param
 * @param {Array} param.fields Array of fields from backend
 * @param {boolean} param.isAdmin Admin does not have required validations
 * @returns yup validation schema
 */
export const getProfileFieldsValidationSchema = ({
  fields,
  isAdmin = false,
}) => {
  const fieldSchema = fields.map(field => {
    const { field_type: fieldType } = field;
    const yupValidator = getFieldTypeValidator(field, isAdmin);
    const fieldName = FIELD_TYPES_TO_BACKEND_VALUE_PARAM[fieldType];
    return yup.object().shape({
      [fieldName]: yupValidator,
      profile_field_id: yup.number().required(),
    });
  });
  return yup.array().tuple(fieldSchema);
};

export const getValidationSchema = fields =>
  yup.object().shape({
    name: yup
      .string()
      .required(
        t([i18nRoot, "is_required"], {
          fieldName: t([i18nRoot, "name"]),
        }),
      )
      .trim()
      .max(
        STRING_MAX_LIMIT,
        t([i18nRoot, "is_max_length"], {
          fieldName: t([i18nRoot, "name"]),
        }),
      ),
    headline: yup
      .string()
      .trim()
      .max(
        STRING_MAX_LIMIT,
        t([i18nRoot, "is_max_length"], {
          fieldName: t([i18nRoot, "headline"]),
        }),
      )
      .nullable(),
    time_zone: yup
      .string()
      .typeError(
        t([i18nRoot, "is_required"], {
          fieldName: t([i18nRoot, "time_zone"]),
        }),
      )
      .trim()
      .required(
        t([i18nRoot, "is_required"], {
          fieldName: t([i18nRoot, "time_zone"]),
        }),
      ),
    community_member_profile_fields_attributes:
      getProfileFieldsValidationSchema({ fields }),
  });
