import Trix from "trix";

export class TrixApiWrapper {
  constructor(editor) {
    this.editor = editor;
  }
  insertAttachment = attributes => {
    const attachment = new Trix.Attachment(attributes);
    this.editor.insertAttachment(attachment);
    return attachment;
  };

  insertLineBreak = () => this.editor.insertLineBreak();
  insertFile = file => this.editor.insertFile(file);
  recordUndoEntry = message => this.editor.recordUndoEntry(message);
  activateAttribute = (attribute, option) =>
    this.editor.activateAttribute(attribute, option);
  deactivateAttribute = attribute => this.editor.deactivateAttribute(attribute);
  attributeIsActive = attribute => this.editor.attributeIsActive(attribute);
  insertString = string => this.editor.insertString(string);
  getSelectedRange = () => this.editor.getSelectedRange();
  setSelectedRange = ([start, end]) =>
    this.editor.setSelectedRange([start, end]);
  getStringInRange = ([start, end]) =>
    this.editor.getDocument().toString().substring(start, end);
  getClientRectAtPosition = position =>
    this.editor.getClientRectAtPosition(position);
  getPosition = () => this.editor.getPosition();
  getDocument = () => this.editor.getDocument();

  insertHr = () => {
    this.insertAttachment({
      // eslint-disable-next-line no-restricted-syntax -- Not a translatable string
      content: "<hr />",
      contentType: "vnd.rubyonrails.horizontal-rule.html",
    });
  };

  getPieceAtPosition = position =>
    this.editor.getDocument().getPieceAtPosition(position);

  getHrefAtPosition = position => {
    const piece = this.getPieceAtPosition(position);
    if (piece) {
      return piece.attributes.values.href;
    }
  };

  getHrefAtCurrentPosition = () => this.getHrefAtPosition(this.getPosition());
  getRangeOfHrefAtPosition = position =>
    this.getRangeOfCommonAttributeAtPosition("href", position);
  getRangeOfCommonAttributeAtPosition = (attribute, position) =>
    this.editor
      .getDocument()
      .getRangeOfCommonAttributeAtPosition(attribute, position);
  getRangeOfHrefAtCurrentPosition = () =>
    this.getRangeOfHrefAtPosition(this.getPosition());

  getClosestAttachment = element => {
    const attachmentElement = element.closest("[data-trix-attachment]");
    return this.getAttachmentById(
      parseInt(attachmentElement.dataset.trixId, 10),
    );
  };
  getAttachmentById = id =>
    this.editor.composition.attachments.find(att => att.id === id);

  removeAttachmentById = id => {
    this.editor.composition.removeAttachment(this.getAttachmentById(id));
  };

  editAttachment = attachment => {
    this.editor.composition.editAttachment(attachment);
  };

  stopEditingAttachment = attachment => {
    this.editor.composition.stopEditingAttachment(attachment);
  };

  addAttributeAtRange = (attribute, value, range) =>
    this.getDocument().addAttributeAtRange(attribute, value, range);

  insertTextAtRange = (text, range) =>
    this.getDocument().insertTextAtRange(text, range);
  getRangeOfAttachment = attachment =>
    this.getDocument().getRangeOfAttachment(attachment);
}
