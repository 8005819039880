import { compact, differenceBy, omit, pick } from "lodash";

/**
 * Compare old and new values to add _destroy parameter
 */
const addDeletedItems = ({ prevData, formData, param = "id" }) => {
  const oldList = prevData.map(({ profile_field_choice: { id } }) => ({
    profile_field_choice_id: id,
  }));
  const deletedItems = differenceBy(oldList, formData, param);
  const destroyParamWithItems = deletedItems.map(deletedItem => {
    const oldListItem = prevData.find(
      ({ profile_field_choice: { id } }) =>
        id === deletedItem.profile_field_choice_id,
    );
    return { id: oldListItem.id, _destroy: true };
  });
  const updatedList = differenceBy(formData, oldList, param);
  return updatedList.concat(destroyParamWithItems);
};

/**
 * There are multiple keys surrounding ID
 * Make sure the key is profile_field_choice_id and no null objects pass through
 */
const prepareChoicesList = list => {
  let arrayList = list;
  if (!Array.isArray(list)) {
    arrayList = [list];
  }
  return compact(arrayList).map(id => ({ profile_field_choice_id: +id }));
};

/**
 * Choices - Select and checkbox requires some special processing
 * @param {number} id field ID
 * @param {*} choicesList current list of choices
 * @param {*} existingProfileFields data from server
 * @returns list with _destroy and compact choices list
 */
const handleChoiceList = (id, choicesList, existingProfileFields) => {
  const newList = prepareChoicesList(choicesList);
  const oldAttribute = existingProfileFields.find(
    profileField => profileField.id === id,
  );
  const oldList =
    oldAttribute.community_member_profile_field?.community_member_choices?.map(
      choice => ({
        ...choice,
        profile_field_choice_id: choice.profile_field_choice.id,
      }),
    ) ?? [];

  return addDeletedItems({
    prevData: oldList,
    formData: newList,
    param: "profile_field_choice_id",
  });
};

/**
 * Invert messages_enabled here
 */
const getPreferences = preferences => ({
  ...pick(preferences, ["visible_in_member_directory", "make_my_email_public"]),
  messaging_enabled: !preferences.prevent_members_from_messaging,
});

export const createCommunityMemberProfileFieldsAttributes = (
  fields = [],
  existingProfileFields,
) =>
  Object.values(omit(fields, ["tuple"])).map(
    communityMemberProfileFieldsAttribute => {
      if (
        "community_member_choices_attributes" in
        communityMemberProfileFieldsAttribute
      ) {
        return {
          ...communityMemberProfileFieldsAttribute,
          community_member_choices_attributes: handleChoiceList(
            communityMemberProfileFieldsAttribute.profile_field_id,
            communityMemberProfileFieldsAttribute.community_member_choices_attributes,
            existingProfileFields,
          ),
        };
      }
      return communityMemberProfileFieldsAttribute;
    },
  );

export const createBackendParams = ({ formData, data }) => {
  const {
    community_member_profile_fields_attributes:
      communityMemberProfileFieldsAttributes,
    preferences,
  } = formData;

  return {
    community_member: {
      ...pick(formData, ["name", "avatar", "headline", "time_zone", "locale"]),
      preferences: getPreferences(preferences),
      community_member_profile_fields_attributes:
        createCommunityMemberProfileFieldsAttributes(
          communityMemberProfileFieldsAttributes,
          data?.profile_fields?.visible,
        ),
    },
  };
};
