import PropTypes from "prop-types";
import { t } from "i18n-js";
import { Icon } from "@circle-react-shared/Icon";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { Typography } from "@circle-react-uikit/Typography";

export const ProfileFieldWithIcon = ({
  title,
  icon,
  text,
  isHidden,
  dataTestId,
}) => (
  <div className="grid gap-2" data-testid={dataTestId}>
    {title && (
      <div className="flex items-center gap-x-1">
        <Typography.LabelSm weight="semibold">{title}</Typography.LabelSm>
        {isHidden && (
          <TippyV2
            className="text-light inline-flex"
            content={t("profile_fields.hidden")}
            aria-label={t("profile_fields.hidden")}
          >
            <Icon
              type="16-eye-closed"
              size={14}
              aria-hidden
              useWithFillCurrentColor
            />
          </TippyV2>
        )}
      </div>
    )}
    <div
      className="text-dark flex items-center gap-3 whitespace-pre-line break-words"
      style={{ wordBreak: "break-word" }}
    >
      {icon && <Icon type={icon} size={16} />}
      {text && <Typography.LabelSm>{text}</Typography.LabelSm>}
    </div>
  </div>
);

ProfileFieldWithIcon.propTypes = {
  title: PropTypes.node,
  icon: PropTypes.string,
  text: PropTypes.node,
  isHidden: PropTypes.bool,
  dataTestId: PropTypes.string,
};
