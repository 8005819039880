import { t } from "i18n-js";
import { Tabs } from "@circle-react-uikit/Tabs";
import { EmbedForm } from "../EmbedForm";

export const EmbedSelector = (
  { embedPlaceholder, helperText, embedBtn, onClose },
  ...props
) => (
  <Tabs defaultValue="Embed link">
    <div label={t("trix_editor.modal_copy.embed_link")}>
      <EmbedForm
        {...props}
        onClose={onClose}
        embedPlaceholder={embedPlaceholder}
        helperText={helperText}
        embedBtn={embedBtn}
      />
    </div>
    <div label=""></div>
  </Tabs>
);
