import { useQuery } from "react-query";
import { planApi } from "@/react/api";

export interface UseFetchPlanProps {
  planTier?: string;
  planId?: string;
  billingCurrency?: string;
  locale?: string;
  planType?: string;
  isMarketingHubRequired?: boolean;
}

export const useFetchPlan = ({
  planTier = "",
  planId = "",
  billingCurrency = "",
  locale = "",
  planType = "",
  isMarketingHubRequired = false,
}: UseFetchPlanProps) => {
  const shouldFetchPlan = Boolean(planTier || planId);
  const shouldFetchMarketingHubPlan = isMarketingHubRequired;

  const {
    data: plan,
    isLoading: isPlanLoading,
    error: errorFetchingPlan,
  } = useQuery(
    ["plan", "fetch", planTier, planId, billingCurrency, locale, planType],
    () =>
      planApi.fetch({
        tier: planTier,
        id: planId,
        billing_currency: billingCurrency,
        locale: locale,
      }),
    {
      enabled: shouldFetchPlan,
    },
  );

  const {
    data: marketingHubPlan,
    isLoading: isMarketingHubPlanLoading,
    error: errorFetchingMarketingHubPlan,
  } = useQuery(
    ["plan", "fetch", "marketing_hub", billingCurrency, locale],
    () =>
      planApi.fetch({
        billing_currency: billingCurrency,
        locale: locale,
        plan_type: "marketing_hub",
      }),
    {
      enabled: shouldFetchMarketingHubPlan,
    },
  );

  return {
    plan,
    isPlanLoading,
    errorFetchingPlan,
    marketingHubPlan,
    isMarketingHubPlanLoading,
    errorFetchingMarketingHubPlan,
  };
};
