import { createContext, useContext, useEffect, useMemo, useState } from "react";
import type { ReactNode } from "react";
import { usePlanStatus } from "@/react/hooks/usePlanStatus";
import { useFetchPlan } from "./hooks/useFetchPlan";

export interface UpgradePlanContextProps {
  billingCurrency?: string;
  canCheckoutInstantly?: boolean;
  couponId?: any;
  plan: any;
  marketingHubPlan: any;
  isPlanLoading: boolean;
  isMarketingHubPlanLoading: boolean;
  errorFetchingPlan: unknown;
  errorFetchingMarketingHubPlan: unknown;
  setBillingCurrency: (currency: string) => void;
  shouldShowCurrencySelection: boolean;
  showPlanDetails: boolean;
  source?: string;
  usedForWorkflows?: boolean;
  isMarketingHubRequired?: boolean;
  isMarketingHubOnlyUpgrade: boolean;
  isDoubleUpgrade: boolean;
}

const UpgradePlanContext = createContext<UpgradePlanContextProps | null>(null);
UpgradePlanContext.displayName = "UpgradePlanContext";

export const useUpgradePlanContext = () => {
  const context = useContext(UpgradePlanContext);
  if (!context) {
    throw new Error(
      "useUpgradePlanContext must be used within an UpgradePlanContext.Provider",
    );
  }
  return context;
};

export interface UpgradePlanProviderProps {
  children: ReactNode;
  planId?: any;
  planTier?: string;
  couponId?: string;
  source?: string;
  usedForWorkflows?: boolean;
  showPlanDetails?: boolean;
  isMarketingHubRequired?: boolean;
}

export const UpgradePlanProvider = ({
  children,
  planId,
  planTier,
  couponId,
  source,
  usedForWorkflows,
  showPlanDetails = false,
  isMarketingHubRequired,
}: UpgradePlanProviderProps) => {
  const [billingCurrency, setBillingCurrency] = useState<string>();
  const [locale, setLocale] = useState<string>();
  const [canCheckoutInstantly, setCanCheckoutInstantly] = useState<boolean>();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const queryLocale = urlParams.get("locale");
    if (queryLocale) {
      setLocale(queryLocale);
    }
  }, []);

  const {
    canChooseCurrency: shouldShowCurrencySelection,
    defaultCurrency: communityDefaultCurrency,
  } = usePlanStatus();

  useEffect(() => {
    if (communityDefaultCurrency) {
      setBillingCurrency(communityDefaultCurrency);
    }
  }, [communityDefaultCurrency]);

  const {
    plan,
    isPlanLoading,
    errorFetchingPlan,
    marketingHubPlan,
    isMarketingHubPlanLoading,
    errorFetchingMarketingHubPlan,
  } = useFetchPlan({
    planTier,
    planId,
    billingCurrency,
    locale,
    isMarketingHubRequired,
  });

  const { shouldShowProration } = usePlanStatus();

  useEffect(() => {
    if (!isPlanLoading && plan) {
      const shouldShowProrationDetails = Boolean(shouldShowProration(plan));
      const canCheckoutInstantly =
        !showPlanDetails && !shouldShowProrationDetails;

      if (!canCheckoutInstantly) {
        setBillingCurrency(plan.currency);
      }

      setCanCheckoutInstantly(canCheckoutInstantly);
    }
  }, [plan, isPlanLoading, showPlanDetails, shouldShowProration]);

  const isMarketingHubOnlyUpgrade = useMemo(
    () =>
      Boolean(
        isMarketingHubRequired &&
          !plan &&
          !!marketingHubPlan &&
          !isMarketingHubPlanLoading,
      ),
    [isMarketingHubRequired, plan, marketingHubPlan, isMarketingHubPlanLoading],
  );

  const isDoubleUpgrade = useMemo(
    () => Boolean(plan && isMarketingHubRequired && marketingHubPlan),
    [plan, isMarketingHubRequired, marketingHubPlan],
  );

  return (
    <UpgradePlanContext.Provider
      value={{
        billingCurrency,
        canCheckoutInstantly,
        couponId,
        errorFetchingPlan,
        isPlanLoading,
        plan,
        setBillingCurrency,
        shouldShowCurrencySelection,
        showPlanDetails,
        source,
        usedForWorkflows,
        isMarketingHubRequired,
        marketingHubPlan,
        isMarketingHubPlanLoading,
        errorFetchingMarketingHubPlan,
        isMarketingHubOnlyUpgrade,
        isDoubleUpgrade,
      }}
    >
      {children}
    </UpgradePlanContext.Provider>
  );
};
