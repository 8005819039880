import type { Argument } from "classnames";
import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { ProfileModalTemplate } from "./ProfileModalTemplate";

export interface ProfileFormFooterProps {
  formId: string;
  onClose: () => void;
  footerContainerClassName?: Argument;
}

export const ProfileFormFooter = ({
  formId,
  footerContainerClassName,
  onClose,
}: ProfileFormFooterProps) => {
  const { formState } = useFormContext();

  return (
    <ProfileModalTemplate.FooterForm
      {...(formState.isDirty && {
        description: t("unsaved_changes"),
      })}
      containerClassName={footerContainerClassName}
      showFooter
      primaryButtonProps={{
        type: "submit",
        variant: "circle",
        form: formId,
        children: t("save_changes"),
        disabled: formState.isSubmitting,
      }}
      secondaryButtonProps={{
        type: "button",
        variant: "secondary",
        onClick: onClose,
        children: t("cancel"),
      }}
    />
  );
};
