import { t } from "i18n-js";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { DropZone } from "../DropZone";
import { ImageError } from "./ImageError";

export interface FileUploadProps {
  imageError?: string;
  insertFiles: (files: FileList | null) => void;
  aspectRatioHelpText?: string;
}

export const FileUpload = ({
  imageError,
  insertFiles,
  aspectRatioHelpText,
}: FileUploadProps) => {
  const isSmScreen = useSmScreenMediaQuery();
  return (
    <div className="upload-form">
      <ImageError error={imageError} />
      <DropZone
        onFileDrop={insertFiles}
        dropTitle={
          isSmScreen
            ? t("image_upload.click_here_to_upload_image")
            : t("image_upload.drag_image")
        }
        helperText={
          <>
            {t("image_upload.works_with_image_type")}
            {aspectRatioHelpText && (
              <>
                <br />
                {aspectRatioHelpText}
              </>
            )}
          </>
        }
      />
    </div>
  );
};
