import { t } from "i18n-js";
import { usePunditUserContext } from "@circle-react/contexts/punditUserContext";
import { useHideEmailsOnMemberProfiles } from "@circle-react/hooks/useHideEmailsOnMemberProfiles";
import { Typography } from "@circle-react-uikit/Typography";
import { ToggleSwitchFormItem } from "./ToggleSwitchFormItem";

export const Permissions = () => {
  const shouldHideEmailOnMemberProfiles = useHideEmailsOnMemberProfiles();
  const { currentCommunity, currentCommunitySettings } = usePunditUserContext();
  const messagingEnabled = currentCommunity?.messaging_enabled;
  const gamificationEnabled = currentCommunitySettings?.gamification_enabled;

  const messagingTooltip = messagingEnabled
    ? t(
        "profile_fields.form.preferences.prevent_members_from_messaging_tooltip",
      )
    : t("account.member_edit_profile_form.direct_messaging_turned_off");

  return (
    <div className="mt-6 flex flex-col gap-2">
      <Typography.LabelMd weight="medium">
        {t("profile_fields.form.preferences.title")}
      </Typography.LabelMd>
      {!shouldHideEmailOnMemberProfiles && (
        <ToggleSwitchFormItem
          name="preferences.make_my_email_public"
          tooltipText={t(
            "profile_fields.form.preferences.make_my_email_public_tooltip",
          )}
        />
      )}
      <ToggleSwitchFormItem
        name="preferences.prevent_members_from_messaging"
        isDisabled={!messagingEnabled}
        tooltipText={messagingTooltip}
      />
      <ToggleSwitchFormItem
        name="preferences.visible_in_member_directory"
        {...(gamificationEnabled && {
          tooltipText: t(
            "profile_fields.form.preferences.visible_in_member_directory_tooltip",
          ),
        })}
      />
    </div>
  );
};
