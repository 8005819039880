export const BigCheckmark = ({ size = 104 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 72 72"
    fill="none"
    aria-hidden="true"
  >
    <circle cx="36" cy="36" r="36" fill="#008A2F" />
    <path
      d="M46.8078 25.4865C47.8595 24.4241 49.5707 24.4179 50.6299 25.4727C51.5929 26.4315 51.6855 27.9371 50.9043 29.0011L50.6437 29.306L33.6105 46.5134C32.6524 47.4812 31.1474 47.5722 30.0866 46.7842L29.7826 46.5215L21.364 38.0884C20.3079 37.0305 20.3069 35.3142 21.3617 34.255C22.3206 33.2921 23.822 33.2037 24.8805 33.9904L25.1838 34.2527L31.6851 40.7603L46.8078 25.4865Z"
      fill="white"
    />
  </svg>
);
