import { createContext, useContext, useEffect } from "react";
import Trix from "trix";
import { TrixApiWrapper } from "./TrixApiWrapper";

export const TrixEditorContext = createContext();
TrixEditorContext.displayName = "TrixEditorContext";
export const useEditorContext = () => useContext(TrixEditorContext);

export const useOnTrixChange = onTrixChange => {
  const editorElement = useEditorContext();
  useEffect(() => {
    if (!editorElement) return;
    editorElement.addEventListener("trix-change", onTrixChange);
    return () => {
      if (editorElement) {
        editorElement.removeEventListener("trix-change", onTrixChange);
      }
    };
  }, [onTrixChange, editorElement]);
};

export const useEditorApi = () => {
  const editorElement = useEditorContext();
  const { editor } = editorElement;
  const insertAttachment = attributes => {
    const attachment = new Trix.Attachment(attributes);
    editor.insertAttachment(attachment);
    return attachment;
  };
  const trixApiWrapper = new TrixApiWrapper(editor);

  const insertLineBreak = () => editor.insertLineBreak();

  const insertFile = file => editor.insertFile(file);

  const recordUndoEntry = message => editor.recordUndoEntry(message);
  const activateAttribute = (attribute, option) =>
    editor.activateAttribute(attribute, option);
  const deactivateAttribute = attribute =>
    editor.deactivateAttribute(attribute);
  const attributeIsActive = attribute => editor.attributeIsActive(attribute);
  const insertString = string => editor.insertString(string);
  const setSelectedRange = ([start, end]) =>
    editor.setSelectedRange([start, end]);
  const getStringInRange = ([start, end]) =>
    editor.getDocument().toString().substring(start, end);
  const getClientRectAtPosition = position =>
    editor.getClientRectAtPosition(position);
  const getPosition = () => editor.getPosition();
  const deleteInDirection = direction => editor.deleteInDirection(direction);
  const insertHr = trixApiWrapper.insertHr;
  const getPieceAtPosition = trixApiWrapper.getPieceAtPosition;
  const getHrefAtPosition = trixApiWrapper.getHrefAtPosition;

  return {
    editorElement,
    trixApiWrapper,
    insertString,
    setSelectedRange,
    insertAttachment,
    insertFile,
    insertLineBreak,
    insertHr,
    recordUndoEntry,
    activateAttribute,
    deactivateAttribute,
    attributeIsActive,
    getStringInRange,
    getClientRectAtPosition,
    getPosition,
    deleteInDirection,
    getPieceAtPosition,
    getHrefAtPosition,
  };
};
