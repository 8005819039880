import PropTypes from "prop-types";
import classNames from "classnames";
import { Block } from "./Block";

export const MediumRectangle = ({ className, ...rest }) => (
  <Block className={classNames("h-3 rounded-[3px]", className)} {...rest} />
);

MediumRectangle.propTypes = {
  className: PropTypes.string,
};
